import React, { useState, useEffect } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Modal, Delete, Button } from "rbx";
import UsersService from "../../../services/users";

function DeleteUserModal({isDeleteModalVisible, setIsDeleteModalVisible, users, setUsers, indexToEdit}) {

    const handleDeleteClick = () =>{
        setIsDeleteModalVisible(false);
        console.log(indexToEdit);
        try{
            UsersService.delete(users[indexToEdit].id).then(() =>{
                console.log(users);
                console.log(indexToEdit);
                users.splice(indexToEdit, 1);
                console.log(users);
                setUsers(users);
            })
        }catch(error){
            console.log(error);
        }
    }
    function handleOutsideClick(e){
        if(e.target.id === "modal") setIsDeleteModalVisible(false);
    }

    return (
        <Fragment>
            <Modal active={isDeleteModalVisible}>
                <Modal.Background id="modal" onClick={handleOutsideClick} />
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Apagar usuário</Modal.Card.Title>
                        <Delete onClick={() => {
                            setIsDeleteModalVisible(false); }} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                            <p>Os dados do usuário serão apagados permanentemente. Deseja continuar?</p>
                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        <Button color="success" onClick={handleDeleteClick}>Sim</Button>

                        <Button onClick={() => {
                            setIsDeleteModalVisible(false);
                        }} >Não</Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </Modal>
        </Fragment>
    );
}

export default DeleteUserModal;