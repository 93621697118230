import React, {Fragment} from "react";

function RegisterScreen(){
    return (
        <Fragment>
            <p>Register Screen</p>
        </Fragment>
    );
}

export default RegisterScreen;