import React from "react";
import {Fragment} from "react/cjs/react.production.min";
import {Control, Field, Input, Label, Help} from "rbx";


function AdressInfo(props) {
    const handleAdressChange = (e) => {
        const { name, value } = e.currentTarget;
        props.setFields(prevState =>({
            ...props.fields,
            endereco : {
                ...prevState.endereco,
                [name] : value,
            }
        }))
    }

    return (
        <Fragment>
            <Field horizontal>
                <Field.Label size="normal">
                    <Label className="has-text-custom-purple">
                        Endereço
                    </Label>
                </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded>
                                <Input type="text" 
                                placeholder="Logradouro" 
                                name='rua'
                                value={props.fields.endereco.rua}
                                onChange={handleAdressChange}
                                disabled={props.isFieldsInvisible} />
                            </Control>
                            {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Logradouro</Help>
                            : ""}
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal>
                    <Field.Label/>

                    <Field.Body>
                        <Field>
                            <Control>
                                <Input 
                                type="text" 
                                placeholder="Número" 
                                name="numero"
                                value={props.fields.endereco.numero}
                                onChange={handleAdressChange}
                                disabled={props.isFieldsInvisible}/>
                            </Control>
                            {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Número</Help>
                            : ""}
                        </Field>
                        <Field>    
                            <Control>
                                <Input 
                                type="text" 
                                placeholder="Bairro" 
                                name="bairro" 
                                value={props.fields.endereco.bairro}
                                onChange={handleAdressChange}
                                disabled={props.isFieldsInvisible}/>
                            </Control>
                            {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Bairro</Help>
                            : ""}
                        </Field>
                    </Field.Body>
                </Field>

                <Field horizontal>
                    <Field.Label/>    
                    <Field.Body>
                        <Field>
                            <Control>
                                <Input
                                    type="text"
                                    placeholder="Cidade"
                                    name="cidade"
                                    value={props.fields.endereco.cidade}
                                    onChange={handleAdressChange}
                                    disabled={props.isFieldsInvisible}/>
                            </Control>
                            {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Cidade</Help>
                            : ""}
                        </Field>
                        <Field>
                            <Control>
                                <Input
                                    type="text"
                                    placeholder="Estado"
                                    name="estado"
                                    value={props.fields.endereco.estado}
                                    onChange={handleAdressChange}
                                    disabled={props.isFieldsInvisible}/>
                            </Control>
                            {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Estado</Help>
                            : ""}
                        </Field>
                    </Field.Body>
                </Field>
                
                <Field horizontal>
                    <Field.Label/>
                    <Field.Body>
                        <Field>
                            <Control>
                                <Input
                                    type="text"
                                    placeholder="Complemento"
                                    name="complemento"
                                    value={props.fields.endereco.complemento}
                                    onChange={handleAdressChange}
                                    disabled={props.isFieldsInvisible}/>
                            </Control>
                            {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Complemento</Help>
                            : ""}
                        </Field>
                    </Field.Body>
                </Field>
        </Fragment>
    );
}

export default AdressInfo;