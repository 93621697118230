import Api from "./api";

const StudentsServices = {
    register : async (params) => {
        params.id = 0
        const response  = await Api.post('/students', params);
        return response;
    },
    list : async (page, size) =>{
        const response = await Api.get(`/students?page=${page}&size=${size}`);
        return response;
    },
    update : async (params) =>{
        Api.patch('/students', params);
    },
    getPerId : async(id) =>{
        const response = await Api.get(`/students/${id}`)
        return response;
    },
    getPerName : async(name) =>{
        const response = await Api.get(`/students?name=${name}`);
        return response;
    },
    delete : async(id) =>{
        Api.delete(`/students/${id}`);
    },
    getListPerClass : async(_class) =>{
        const response = await Api.get(`/students?class=${_class}`);
        return response;
    },
    getActivities : async(id, quantity, page) =>{
        const response = await Api.get(`/students/${id}/activities?size=${quantity}&page=${page}`);
        return response.data;
    },
    getActivitiesStats : async(id) =>{
        const response = await Api.get(`/students/${id}/stats`);
        return response.data;
    },
    filterStudentsbyMonth : async(month) => {
        const response = await Api.get(`/students?month=${month}`);
        return response;
    },
    filterScheduledStudents : async() => {
        const response = await Api.get("students-waiting");
        return response;
    },
    getStudents :  async(params) => {
        const response = await Api.get(`/students?${params}`);
        return response;
    },
    getReports : async(params) => {
        const response =  await Api.get(`/students/reports?${params}`);
        return response;
    },
    sendFile : async(params) => {
        const formData = new FormData();
        formData.append("file", params);
        const headers = {
            'headers': {
                'Content-Type': 'application/json'
            }
        }
        const response = await Api.post("students/reports", formData, headers);
        return response;
    }
}

export default StudentsServices;