import React, {useState, useEffect} from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Column} from "rbx";
import { faSearch, faSignOutAlt, faUserGraduate, faUsersCog } from "@fortawesome/free-solid-svg-icons";
import "../../styles/home.scss"
import MenuButton from "../menu-button";


function Menu(props) {
    const [isRegistrationDisabled, setIsRegistrationDisabled] = useState(false);
    const [isAdminDisabled, setIsAdminDisabled] = useState(false);
    
    useEffect(() =>{
        let role = localStorage.getItem('role');
        if(role !== '1' && role !== '2' ){
            setIsAdminDisabled(true);
            setIsRegistrationDisabled(true);
        }else if(role !== '1'){
            setIsAdminDisabled(true);
        }
    }, [])
    

    return (
        <Fragment>

            <Column.Group className="pt-2 mx-1 menu">
                <Column narrow >
                    <MenuButton
                        isButtonDisabled={isRegistrationDisabled}
                        text="Cadastrar aluno  "
                        icon={faUserGraduate}
                        name="studentRegistration"
                        handleClick={props.handleClick} />
                    <MenuButton
                        text="Buscar aluno"
                        icon={faSearch}
                        name="searchStudent"
                        handleClick={props.handleClick} />
                </Column>

                <Column narrow>
                    <MenuButton
                        isButtonDisabled={isAdminDisabled}
                        text="Admin"
                        icon={faUsersCog}
                        name="adminTools"
                        handleClick={props.handleClick} />
                    <MenuButton
                        text="Sair"
                        icon={faSignOutAlt}
                        name="exit"
                        handleClick={props.handleClick} />
                </Column>

            </Column.Group>

        </Fragment>
    );
}

export default Menu;