import React, { useEffect } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Control, Field, Label, Radio, Container } from "rbx";

function FinalQuestions(props) {
    const handleFieldsChange = (e) => {
        console.log(e.currentTarget);
        props.setFields({
            ...props.fields,
            [e.currentTarget.name]: e.currentTarget.value
        })
    };

    return (
        <Fragment>
            <Field horizontal>
                <Field.Label>
                    <Label className="has-text-custom-purple">Aprovado?</Label>
                </Field.Label>
                <Field.Body>
                    <Field>
                        <Control>
                            <Label>
                                <Radio
                                    name="aprovado"
                                    value='true'
                                    checked={props.fields.aprovado === 'true' ? true : false}
                                    onChange={handleFieldsChange}
                                    disabled={props.isFieldsInvisible}
                                /> Sim
                            </Label>
                            <Label>
                                <Radio
                                    name="aprovado"
                                    value='false'
                                    checked={props.fields.aprovado === 'false' ? true : false}
                                    onChange={handleFieldsChange}
                                    disabled={props.isFieldsInvisible}
                                /> Não
                            </Label>
                        </Control>
                    </Field>
                    <Field horizontal>
                        <Field.Label>
                            <Label className="has-text-custom-purple">Renovou?</Label>
                        </Field.Label>
                        <Field.Body>
                            <Field>
                                <Control>
                                    <Label>
                                        <Radio
                                            name="renovou"
                                            value='true'
                                            checked={props.fields.renovou === 'true' ? true : false}
                                            onChange={handleFieldsChange}
                                            disabled={props.isFieldsInvisible}
                                        /> Sim
                                    </Label>
                                    <Label>
                                        <Radio
                                            name="renovou"
                                            value='false'
                                            checked={props.fields.renovou === 'false' ? true : false}
                                            onChange={handleFieldsChange}
                                            disabled={props.isFieldsInvisible}
                                        /> Não
                                    </Label>
                                </Control>
                            </Field>
                        </Field.Body>
                    </Field>
                    <Field horizontal>
                        <Field.Label>
                            <Label className="has-text-custom-purple">Está na comunidade?</Label>
                        </Field.Label>
                        <Field.Body>
                            <Field>
                                <Control>
                                    <Label>
                                        <Radio
                                            name="comunidade"
                                            value='true'
                                            checked={props.fields.comunidade === 'true' ? true : false}
                                            onChange={handleFieldsChange}
                                            disabled={props.isFieldsInvisible}
                                        /> Sim
                                    </Label>
                                    <Label>
                                        <Radio
                                            name="comunidade"
                                            value='false'
                                            checked={props.fields.comunidade === 'false' ? true : false}
                                            onChange={handleFieldsChange}
                                            disabled={props.isFieldsInvisible}
                                        /> Não
                                    </Label>
                                </Control>
                            </Field>
                        </Field.Body>
                    </Field>
                </Field.Body>
            </Field>
            <Field horizontal>
                <Field.Label>
                    <Label className="has-text-custom-purple">Curso grammar?</Label>
                </Field.Label>
                <Field.Body>
                    <Field>
                        <Control>
                            <Label>
                                <Radio
                                    name="curso_grammar"
                                    value='true'
                                    checked={props.fields.curso_grammar === 'true' ? true : false}
                                    onChange={handleFieldsChange}
                                    disabled={props.isFieldsInvisible}
                                /> Sim
                            </Label>
                            <Label>
                                <Radio
                                    name="curso_grammar"
                                    value='false'
                                    checked={props.fields.curso_grammar === 'false' ? true : false}
                                    onChange={handleFieldsChange}
                                    disabled={props.isFieldsInvisible}
                                /> Não
                            </Label>
                        </Control>
                    </Field>
                    <Field horizontal>
                        <Field.Label>
                            <Label className="has-text-custom-purple">Preparatório para a prova?</Label>
                        </Field.Label>
                        <Field.Body>
                            <Field>
                                <Control>
                                    <Label>
                                        <Radio
                                            name="preparatorio_prova"
                                            value='true'
                                            checked={props.fields.preparatorio_prova === 'true' ? true : false}
                                            onChange={handleFieldsChange}
                                            disabled={props.isFieldsInvisible}
                                        /> Sim
                                    </Label>
                                    <Label>
                                        <Radio
                                            name="preparatorio_prova"
                                            value='false'
                                            checked={props.fields.preparatorio_prova === 'false' ? true : false}
                                            onChange={handleFieldsChange}
                                            disabled={props.isFieldsInvisible}
                                        /> Não
                                    </Label>
                                </Control>
                            </Field>
                        </Field.Body>
                    </Field>
                    <Field horizontal>
                        <Field.Label>
                            <Label className="has-text-custom-purple">Dois anos?</Label>
                        </Field.Label>
                        <Field.Body>
                            <Field>
                                <Control>
                                    <Label>
                                        <Radio
                                            name="dois_anos"
                                            value='true'
                                            checked={props.fields.dois_anos === 'true' ? true : false}
                                            onChange={handleFieldsChange}
                                            disabled={props.isFieldsInvisible}
                                        /> Sim
                                    </Label>
                                    <Label>
                                        <Radio
                                            name="dois_anos"
                                            value='false'
                                            checked={props.fields.dois_anos === 'false' ? true : false}
                                            onChange={handleFieldsChange}
                                            disabled={props.isFieldsInvisible}
                                        /> Não
                                    </Label>
                                </Control>
                            </Field>
                        </Field.Body>
                    </Field>
                </Field.Body>

            </Field>
        </Fragment>
    );
}

export default FinalQuestions;