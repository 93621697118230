import React, { useState, useEffect } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Panel, Control, Icon, Pagination, Input, Column, Select } from 'rbx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import '../../styles/right-screen.scss';
import StudentsServices from "../../services/students";
import DeleteStudentModal from "../modals/delete-student-modal";
import Filters from "../filters";


function StudentList({ setOption, data, setData,
    setIsFieldsInvisible, setNoteList, searchBar, setSearchBar,
    optionsHistoric, setOptionsHistoric }) {
    const [page, setPage] = useState(1);
    const [items, setItems] = useState([]);
    const [pagesQt, setPagesQt] = useState();
    const [indexToDelete, setIndexToDelete] = useState();
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [query, setQuery] = useState(`page=${page}&size=15`);
    const [removeFilters, setRemoveFilters] = useState(false);
    const [file, setFile] = useState("");
    

    const QUERY_INITIAL_STATE = `page=${page}&size=15`;
    const { searchBarInput } = searchBar;

    useEffect(() => {
        return () => {
            const historic = optionsHistoric;
            historic.push("searchStudent");
            setOptionsHistoric(historic);
        }

    }, [])
    useEffect(() => {
        StudentsServices.getStudents(query).then((response) => {
            let pages = Math.ceil(Number(response.headers["total-students"]) / 15);
            console.log(pages);
            setPagesQt(pages);
            setItems(response.data);
        });

        console.log(query);

    }, [query]);

    const handleStudentClick = (e) => {
        if (e.currentTarget.getAttribute('id') === e.target.getAttribute('id')) {
            console.log(e.currentTarget.getAttribute('id'));
            StudentsServices.getPerId(items[e.currentTarget.getAttribute('id')].id).then((response) => {
                setData({ ...response.data });
                setNoteList([data.observacoes]);
                setIsFieldsInvisible(true);
                setOption("studentRegistration");

            })
            console.log(StudentsServices.getActivities(e.currentTarget.getAttribute('id'), 10, 1));
        } else {
            setIndexToDelete(items[e.currentTarget.getAttribute('id')].id);
        }
    }

    const handleSearchBarChange = (e) => {
        setSearchBar({
            ...searchBar,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    async function sendFile(file){
       await StudentsServices.sendFile(file).then((response) => {
            if(response.data !== undefined && response.data.inserted !== undefined){
                alert(`${response.data.inserted} alunos inseridos com sucesso!`);
            }
       });
    }

    return (
        <Fragment>
            <div className="right-content">
                <Panel className="panel-student-list" >
                    <Panel.Heading className="ml-3">Lista de alunos</Panel.Heading>
                    <div className="block">
                        <div className="columns">
                            <div className="column ml-3 is-2">
                                <div className="button is-small" onClick={() => {
                                    setIsFiltersOpen(!isFiltersOpen);
                                }}>Filtros</div>

                            </div>
                            <div className="column">
                                <a onClick={() => {
                                    setRemoveFilters(true);
                                    setQuery(QUERY_INITIAL_STATE);
                                }}>
                                    Remover filtros
                                </a>
                            </div>
                            <div className="column">
                                <div className="file is-small ">
                                    <label className="file-label">
                                        <input className="file-input" 
                                                type="file" 
                                                name="resume"
                                                onChange={(e) => {
                                                    if(e.target.files[0] !== undefined) setFile(e.target.files[0]);
                                                    else setFile("");
                                                }}/>
                                            <span className="file-cta">
                                                <span className="file-icon">
                                                    <i className="fas fa-upload"></i>
                                                </span>
                                                <span className="file-label">
                                                    {file.name || "Inserir planilha..."}
                                                </span>
                                                
                                            </span>
                                            <span>
                                                {file !== undefined && file.name !== undefined &&(
                                                    <button 
                                                        className="button is-small"
                                                        onClick={() => {
                                                            sendFile(file);
                                                        }}>Enviar</button>
                                                )}
                                            </span>
                                            
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Panel.Block>
                        <Control iconLeft>
                            <Input
                                size="small"
                                type="text"
                                placeholder="search"
                                name='searchBarInput'
                                value={searchBar.searchBarInput}
                                onChange={handleSearchBarChange}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faSearch} />
                            </Icon>
                        </Control>
                    </Panel.Block>
                    <div className="block">
                        <Filters
                            isModalVisible={isFiltersOpen}
                            setIsModalVisible={setIsFiltersOpen}
                            setQuery={setQuery}
                            page={page}
                            searchBarInput={searchBarInput}
                            removeFilters={removeFilters}
                            setRemoveFilters={setRemoveFilters} />
                    </div>


                    {items.map((item, index) => {
                        return (
                            <Panel.Block
                                as="a"
                                active
                                id={index}
                                key={index}
                                onClick={handleStudentClick}
                            >
                                <Column.Group
                                    breakpoint="mobile"
                                    className="student-list-column"
                                    id={index}>
                                    <Column
                                        size='one-third'
                                        narrow
                                        id={index}>
                                        <Column.Group
                                            breakpoint="mobile"
                                            id={index}>
                                            <Column
                                                size={1}
                                                id={index}>
                                                <Panel.Icon id={index} className="student-icon" >
                                                    <FontAwesomeIcon icon={faUserGraduate} />
                                                </Panel.Icon>
                                            </Column>

                                            <Column id={index} narrow>
                                                <span
                                                    className="student-name"
                                                    id={index}
                                                >{item.nome}</span>
                                            </Column>
                                        </Column.Group>
                                    </Column>
                                    <Column>
                                        <DeleteStudentModal
                                            id={index}
                                            indexToDelete={indexToDelete}
                                            items={items}
                                            setItems={setItems}
                                            setIndexToDelete={setIndexToDelete} />
                                    </Column>

                                </Column.Group>

                            </Panel.Block>
                        )
                    })}
                </Panel>
                <Pagination className="student-list-pagination" >
                    <Pagination.Step
                        align="previous"
                        onClick={() => {
                            if (page > 1) setPage((page - 1));
                        }}>Anterior</Pagination.Step>
                    <Pagination.Step
                        align="next"
                        onClick={() => {
                            if (page < pagesQt) setPage((page + 1));
                        }}
                    >Próxima</Pagination.Step>
                    <Pagination.List>

                        <Select.Container>
                            <Select onClick={(e) => {
                                setPage(Number(e.currentTarget.value))
                            }}>
                                {Array.from(Array(pagesQt), (item, index) => {
                                    return <Select.Option
                                        key={index}
                                        value={(index + 1)}
                                    >
                                        {(index + 1)}
                                    </Select.Option>
                                })}

                            </Select>
                        </Select.Container>

                    </Pagination.List>




                </Pagination>
            </div>

        </Fragment>
    );
}

export default StudentList;