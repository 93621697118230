import React, { useEffect, useState } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Dropdown, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "../../styles/right-screen.scss";

function MenuMobile(props) {
  const [isRegistrationDisabled, setIsRegistrationDisabled] = useState(false);
  const [isAdminDisabled, setIsAdminDisabled] = useState(false);

  useEffect(() => {
    let role = localStorage.getItem('role');
    if (role !== '1' && role !== '2') {
      setIsAdminDisabled(true);
      setIsRegistrationDisabled(true);
    } else if (role !== '1') {
      setIsAdminDisabled(true);
    }
  }, [])


  return (
    <Fragment>
      <Dropdown className="menu-mobile">
        <Dropdown.Trigger>
          <Button className="menu-mobile-button">
            <span>Menu</span>
            <Icon size="small">
              <FontAwesomeIcon icon={faAngleDown} />
            </Icon>
          </Button>
        </Dropdown.Trigger>
        <Dropdown.Menu>
          <Dropdown.Content>

            {!isRegistrationDisabled
              ? <Fragment>
                <Dropdown.Item
                  name="studentRegistration"
                  onClick={props.handleClick}
                >Cadastrar aluno</Dropdown.Item>
                <Dropdown.Divider />
              </Fragment>
              : ''
            }

            <Dropdown.Item
              name="searchStudent"
              onClick={props.handleClick}
            >Buscar aluno</Dropdown.Item>
            <Dropdown.Divider />

            {!isAdminDisabled
              ? <Fragment>
                <Dropdown.Item
                  name="adminTools"
                  onClick={props.handleClick}
                >Admin</Dropdown.Item>
                <Dropdown.Divider />
              </Fragment>

              : ''
            }


            <Dropdown.Item
              name="exit"
              onClick={props.handleClick}
            >Sair</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
}

export default MenuMobile;
