import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoginScreen from "./screens/auth/login";
import RegisterScreen from "./screens/auth/register";
import HomeScreen from "./screens/home";
import PrivateRouter from "./components/auth/private-router";


function Switch(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginScreen/>}/>
                <Route element={<PrivateRouter/>}>
                    <Route path="/register" element={<RegisterScreen/>}/>
                    <Route path="/home" element={<HomeScreen/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Switch;