import React, { useState, useEffect } from "react";
import { Modal, Button, Icon, Delete, Content, Pagination, Select } from 'rbx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";
import StudentsServices from "../../../services/students";
import Activity from "../../activity";

function ActivitiesModal(props) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activities, setActivities] = useState([]);
    const [page, setPage] = useState(1);
    const [pageQt, setPageQt] = useState();


    useEffect(() => {
        getActivities();
        getPageQt();
    }, [])

    useEffect(() => {
        console.log(activities);
    }, [activities]);

    useEffect(() => {
        getActivities();
    }, [page])

    const handleModalClick = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
        getActivities();
    }

    const handleDelete = () => {
        setIsModalVisible(false);
        setActivities([]);
    }

    const getActivities = () => {
        if (props.id) {
            StudentsServices.getActivities(props.id, 10, page).then((activities) => {
                setActivities(activities);
            })
        }
    }

    const getPageQt = () => {
        if (props.id) {
            StudentsServices.getActivitiesStats(props.id).then((response) => {
                let pages = Math.ceil(response.activities / 10);
                setPageQt(pages);
            })
        }
    }

    function handleOutsideClick(e){
        if(e.target.id === "modal") setIsModalVisible(false);
    }

    return (
        <Fragment>
            <Button onClick={(e) => {
                handleModalClick(e)
            }}
                className="comments-btn"
            >
                <Icon size="small" align="left">
                    <FontAwesomeIcon icon={faList} />
                </Icon>
                <span>Atividades</span>
            </Button>
            <Modal active={isModalVisible}>
                <Modal.Background id='modal' onClick={handleOutsideClick} />
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Feed de atividades</Modal.Card.Title>
                        <Delete onClick={() => handleDelete()} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <Content>

                            {activities.length > 0
                                ? activities.map((item, index) => {
                                    return <Activity key={index} activity={item} />
                                })
                                : "Não há atividades"}

                        </Content>
                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        <Pagination >
                            <Pagination.Step
                                align="previous"
                                onClick={() => {
                                    if (page > 1) setPage((page - 1));
                                }}>Anterior</Pagination.Step>
                            <Pagination.Step
                                align="next"
                                onClick={() => {
                                    if (page < pageQt) setPage((page + 1));
                                }}
                            >Próxima</Pagination.Step>
                            <Pagination.List>
                                <Select.Container>
                                    <Select onClick={(e) => {
                                        setPage(Number(e.currentTarget.value))
                                        console.log(e.currentTarget.value);
                                    }}>
                                        {Array.from(Array(pageQt), (item, index) => {
                                            return <Fragment>
                                                <option
                                                    key={index}
                                                    value={(index + 1)}
                                                    onClick={(e) => {
                                                        console.log(e.currentTarget);
                                                    }}
                                                >
                                                    {(index + 1)}
                                                </option>
                                            </Fragment> 
                                            
                                        })}
                                    </Select>
                                </Select.Container>
                            </Pagination.List>
                        </Pagination>
                    </Modal.Card.Foot>
                </Modal.Card>
            </Modal>

        </Fragment>
    );
}

export default ActivitiesModal;