import React, { Fragment, useState, useEffect } from "react";
import { Button, Control, Field, Icon, Input, Help } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUser, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "../../../styles/auth.scss"
import { Navigate } from "react-router-dom";
import UsersService from "../../../services/users";
import ChangePasswordSecondStep from "../change-password/second-step";
import NotificationModal from "../../modals/notification-modal";


function LoginForm() {
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [redirectToHome, setRedirectToHome] = useState(false);
    const [error, setError] = useState();
    const [isChangeVisible, setIsChangeVisible] = useState(false);
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);
    const [fieldType, setFieldType] = useState('password');
    const [fieldIcon, setFieldIcon] = useState(faEye);

    const handleSubmit = async (evt) => {
        evt.preventDefault();


        let response = await UsersService.login({ email: user, senha: password });
        if (response !== 'unauthorized') {
            setRedirectToHome(true);
        } else {
            setError('danger');
        }


    }

    const handleShowPasswordClick = () => {
        if (fieldType === 'password') {
            setFieldType('text');
            setFieldIcon(faEyeSlash);
        }
        else {
            setFieldType('password');
            setFieldIcon(faEye);
        }

    }

    if (redirectToHome === true) {
        return <Navigate to={{ pathname: "/home" }} />
    }


    return (
        <Fragment>
            <form onSubmit={handleSubmit}>
                <Field>
                    <Control iconLeft iconRight>
                        <Input type="text"
                            required
                            color={error}
                            placeholder="Email"
                            value={user}
                            onChange={e => setUser(e.target.value)}
                        />
                        <Icon size="small" align="left">
                            <FontAwesomeIcon icon={faUser} />
                        </Icon>
                    </Control>
                </Field>
                <Field kind='addons'>
                    <Field className="password-input">
                        <Control iconLeft>
                            <Input type={fieldType}
                                placeholder="Senha"
                                color={error}
                                required
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                            <Icon size="small" align="left" >
                                <FontAwesomeIcon icon={faLock} />
                            </Icon>
                        </Control>
                        {error
                            ? <Help color={error} >Usuário ou senha inválidos</Help>
                            : ''}
                    </Field>

                    <Control>
                        <Button
                            as='a'
                            onClick={(e) => {
                                e.preventDefault();
                                handleShowPasswordClick();
                            }
                            }>
                            <Icon size='small'>
                                <FontAwesomeIcon icon={fieldIcon} />
                            </Icon>
                        </Button>
                    </Control>

                </Field>
                <Field>
                    <Control>
                        <a onClick={(e) => {
                            e.preventDefault();
                            setIsChangeVisible(true);
                        }}>Esqueceu sua senha?</a>
                        <Button.Group hasAddons align="right">
                            <Button
                                className="customButton"
                                id='join'
                            >Entrar</Button>
                        </Button.Group>
                    </Control>
                </Field>
            </form>
            <ChangePasswordSecondStep
                isModalVisible={isChangeVisible}
                setIsModalVisible={setIsChangeVisible}
                showCode={true}
                setIsNotificationVisible={setIsNotificationVisible}
                changeToken=''
            />
            <NotificationModal
                text='Senha alterada com sucesso!'
                isModalVisible={isNotificationVisible}
                setIsModalVisible={setIsNotificationVisible}
            />
        </Fragment>
    );
}

export default LoginForm;