import React from "react";
import {Fragment} from "react/cjs/react.production.min";
import {Control, Field, Icon, Input, Label, Help} from "rbx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMobileAlt, faPhone, faUser} from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";

function ContactInfo(props) {
    const handleFieldsChange = (e) =>{
        props.setFields({
        ...props.fields,
        [e.currentTarget.name] : e.currentTarget.value
        })
    };
    return (
        <Fragment>
            <Field horizontal>
                <Field.Label size="normal">
                    <Label className="has-text-custom-purple">Contato</Label>
                </Field.Label>
                <Field.Body>
                        <Field>
                            <Control expanded iconLeft>
                                <Input
                                    required 
                                    type="text" 
                                    placeholder="Nome"
                                    color={props.nameError} 
                                    name="nome"
                                    value={props.fields.nome}
                                    onChange={handleFieldsChange}
                                    disabled={props.isFieldsInvisible}/>
                                <Icon size="small" align="left">
                                    <FontAwesomeIcon icon={faUser} />
                                </Icon>
                            </Control>
                            {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Nome</Help>
                            : ""}
                            
                        </Field>
                        <Field>
                            <Control expanded iconLeft iconRight>
                                <Input
                                    placeholder="Email"
                                    type="email"
                                    name="email"
                                    value={props.fields.email}
                                    onChange={handleFieldsChange}
                                    disabled={props.isFieldsInvisible}
                                />
                                <Icon size="small" align="left">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </Icon>
                            </Control>
                            {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Email</Help>
                            : ""}
                        </Field>
                    </Field.Body>
                </Field>
                <Field horizontal>
                    <Field.Label size="normal">

                    </Field.Label>
                    <Field.Body>
                        <Field>
                            <Control expanded iconLeft>
                                <InputMask 
                                    type="tel"
                                    className="input"
                                    mask="(99)9999-9999" 
                                    placeholder="Telefone" 
                                    name="telefone"
                                    value={props.fields.telefone}
                                    onChange={handleFieldsChange}
                                    disabled={props.isFieldsInvisible}/>
                                <Icon size="small" align="left">
                                    <FontAwesomeIcon icon={faPhone} />
                                </Icon>
                            </Control>
                            {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Telefone</Help>
                            : ""}
                        </Field>
                        <Field>
                            <Control expanded iconLeft iconRight>
                                <InputMask
                                    placeholder="Celular"
                                    className="input"
                                    mask="(99)99999-9999"
                                    type="tel"
                                    name="celular"
                                    value={props.fields.celular}
                                    onChange={handleFieldsChange}
                                    disabled={props.isFieldsInvisible}
                                />
                                <Icon size="small" align="left">
                                    <FontAwesomeIcon icon={faMobileAlt} />
                                </Icon>
                            </Control>
                            {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Celular</Help>
                            : ""}
                        </Field>
                    </Field.Body>
                </Field>
        </Fragment>
    );
}

export default ContactInfo;