import React, { useState, useEffect } from "react";
import { Fragment } from "react/cjs/react.production.min";
import HeaderLogged from "../../components/header/header_logged";
import Menu from "../../components/menu";
import StudentRegistrationForm from "../../components/student-registration/student-registration-form";
import { Box, Column, Content } from "rbx";
import "../../styles/right-screen.scss";
import StudentList from "../../components/student-list";
import AdminTools from "../../components/admin-tools";
import { Navigate } from "react-router-dom";
import MenuMobile from "../../components/menu-mobile";
import UsersService from "../../services/users";

function HomeScreen() {
    let initialState = {
        id: '',
        nome: '',
        telefone: '',
        celular: '',
        email: '',
        endereco: {
            rua: '',
            numero: '',
            bairro: '',
            cidade: '',
            estado: '',
            complemento: ''
        },
        nascimento: '',
        entrada: '',
        encerramento: '',
        valor_pago: '',
        pagamento: '',
        bonus: '',
        status: '',
        plano_de_estudos: '',
        nota_necessaria: '',
        nivel: '',
        data_da_prova: '',
        aprovado: 'true',
        renovou: 'true',
        comunidade: 'true',
        curso_grammar: 'true',
        preparatorio_prova: 'true',
        turma: '',
        prova: '',
        observacoes: [],
        dois_anos : 'true',
        valor_dois_anos : '',
        data_da_renovacao : '',
        valor_da_renovacao : ''
    }

    
    const [option, setOption] = useState("searchStudent");
    const [data, setData] = useState({ ...initialState });
    const [isFieldsInvisible, setIsFieldsInvisible] = useState(true);
    const [noteList, setNoteList] = useState([]);
    const [searchBar, setSearchBar] = useState({ searchBarInput: '' });
    const [optionsHistoric, setOptionsHistoric] = useState([]);

  


    const handleClick = (e) => {
        
        if (e.currentTarget.name == 'studentRegistration') {
            setData({ ...initialState });
            setIsFieldsInvisible(false);
        }

        setOption(e.currentTarget.name);

        if(e.currentTarget.name === "exit"){
            console.log("passou aqui");
            UsersService.logout();
            setOptionsHistoric([]);
        }


    }

    function handleBackButtonClick(){
        const actualOption = optionsHistoric.pop();
        setOption(actualOption);
    }


    return (
        <Fragment>
            <HeaderLogged />
            <Content className="has-background-custom-background"   >
                <Column.Group>
                    <Column narrow className="menu-field">
                        <Menu handleClick={handleClick} />
                        <MenuMobile handleClick={handleClick} />
                    </Column>
                    <Column className="right-box">
                        <Box>
                            <div className="block">
                                <a onClick={handleBackButtonClick}>
                                    <span class="icon-text">
                                        <span className="icon">
                                            <i className="fa-solid fa-arrow-left"></i>
                                        </span>
                                        <span>Voltar</span>
                                    </span>
                                </a>
                            </div>
                            {option === "studentRegistration" && (
                                <StudentRegistrationForm
                                    data={data}
                                    setData={setData}
                                    setIsFieldsInvisible={setIsFieldsInvisible}
                                    isFieldsInvisible={isFieldsInvisible}
                                    noteList={noteList}
                                    setNoteList={setNoteList}
                                    optionsHistoric={optionsHistoric}
                                    setOptionsHistoric={setOptionsHistoric} />
                            )}
                            {option === "adminTools" && (
                                <AdminTools
                                    optionsHistoric={optionsHistoric}
                                    setOptionsHistoric={setOptionsHistoric} />
                            )}
                            {option === "searchStudent" && (
                                <StudentList
                                    data={data}
                                    setData={setData}
                                    setIsFieldsInvisible={setIsFieldsInvisible}
                                    setNoteList={setNoteList}
                                    noteList={noteList}
                                    searchBar={searchBar}
                                    setSearchBar={setSearchBar}
                                    setOption={setOption}
                                    optionsHistoric={optionsHistoric}
                                    setOptionsHistoric={setOptionsHistoric} />
                            )}
                            {option === "exit" && (
                                <Navigate to="/"/>
                            )}
                        </Box>
                    </Column>
                </Column.Group>

            </Content>

        </Fragment>
    );
}

export default HomeScreen;