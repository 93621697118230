import React from "react";
import {Container, Navbar} from "rbx";
import LogoImage from "../../../assets/images/logo-2.png";
import "../../../styles/header.scss";

function Header() {
    return (
        <Navbar>
            <Container className="navbar-unlogged" fluid>
                <Navbar.Brand>
                    <img src={LogoImage} alt="logo"/>
                </Navbar.Brand>

                <Navbar.Menu id="navbar-menu">
                    <Navbar.Segment as="div" className="navbar-item navbar-end" align="right">
                    </Navbar.Segment>
                </Navbar.Menu>
            </Container>
        </Navbar>
    );
}

export default Header;