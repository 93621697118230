export const DEFAULTS = {
    colors: [
        "primary",
        "success",
        "info",
        "warning",
        "danger",
        "light",
        "dark",
        "white",
        "black",
        "link",
    ],
    shades: [
        "black-bis",
        "black-ter",
        "grey-darker",
        "grey-dark",
        "grey",
        "grey-light",
        "grey-lighter",
        "white-ter",
        "white-bis",
    ],
    badgeSizes: ["small", "medium", "large"],
    floatPulledAlignments: ["left", "right"],
    breakpoints: [
        "mobile",
        "tablet",
        "desktop",
        "widescreen",
        "fullhd",
        "touch",
    ],
    breakpointsLimited: ["mobile", "fullhd", "touch"],
    tooltipPositions: ["top", "right", "bottom", "left"],
    textAlignments: ["centered", "justified", "left", "right"],
    textSizes: [1, 2, 3, 4, 5, 6, 7],
    textTransforms: ["capitalized", "lowercase", "uppercase"],
    textWeights: ["light", "medium", "normal", "semibold", "bold"],
    displays: ["block", "flex", "inline", "inline-block", "inline-flex"],
};
