import React, { Fragment } from "react";
import HeaderUnlogged from "../../../components/header/header-unlogged";
import { Card, Column, Container, Section, Image } from "rbx";
import "../../../styles/auth.scss";
import LoginForm from "../../../components/auth/login_form";
import logo from "../../../assets/images/logo-2.png";
import UsersService from "../../../services/users";


function LoginScreen() {
    localStorage.removeItem('user');
    return (
        <Fragment>
            <HeaderUnlogged />
            <Section className="auth">
                <Container>
                    <Column.Group centered>
                        <Column size={3}>
                            <Card className="login-card">
                                <Section className="p-2">
                                    <Card.Content>
                                        <Column.Group centered>
                                            <Column size={12} className="is-flex is-justify-content-center">
                                                <Card.Image>
                                                    <Image.Container size={128}>
                                                        <Image src={logo} />
                                                    </Image.Container>
                                                </Card.Image>
                                            </Column>
                                        </Column.Group>
                                        <LoginForm />
                                    </Card.Content>
                                </Section>
                            </Card>
                        </Column>
                    </Column.Group>
                </Container>
            </Section>
        </Fragment>
    );

}

export default LoginScreen;