import React from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Control, Field, Icon, Input, Label, Help } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";


function DatesInfo(props) {
    const handleFieldsChange = (e) => {
        props.setFields({
            ...props.fields,
            [e.currentTarget.name]: e.currentTarget.value
        })
    };

    return (
        <Fragment>
            <Field horizontal>
                <Field.Label size="normal">
                    <Label className="has-text-custom-purple">Datas</Label>
                </Field.Label>
                <Field.Body>
                    <Field>
                        <Control expanded iconLeft>
                            <InputMask
                                placeholder="Nascimento"
                                name="nascimento"
                                mask="99/99/9999"
                                type="text"
                                className="input"
                                value={props.fields.nascimento}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </Icon>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Nascimento</Help>
                            : ""}
                    </Field>
                    <Field>
                        <Control expanded iconLeft>
                            <InputMask
                                className="input"
                                name="entrada"
                                mask="99/99/9999"
                                placeholder="Entrada"
                                type="text"
                                value={props.fields.entrada}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </Icon>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Entrada</Help>
                            : ""}
                    </Field>
                    <Field>
                        <Control expanded iconLeft>
                            <InputMask
                                placeholder="Encerramento"
                                name="encerramento"
                                type="text"
                                mask="99/99/9999"
                                className="input"
                                value={props.fields.encerramento}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </Icon>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Encerramento</Help>
                            : ""}
                    </Field>
                </Field.Body>
            </Field>
            <Field horizontal>
                <Field.Label />
                <Field.Body>
                    <div className="columns">
                        <div className="column">
                            <Field>
                                <Control expanded iconLeft>
                                    <InputMask
                                        placeholder="Data da renovação"
                                        name="data_da_renovacao"
                                        type="text"
                                        mask="99/99/9999"
                                        className="input"
                                        value={props.fields.data_da_renovacao}
                                        onChange={handleFieldsChange}
                                        disabled={props.isFieldsInvisible}
                                    />
                                    <Icon size="small" align="left">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </Icon>
                                </Control>
                                {props.isFieldsInvisible || props.fields.id !== ''
                                    ? <Help>Renovação</Help>
                                    : ""}
                            </Field>
                        </div>
                    </div>
                </Field.Body>
            </Field>
        </Fragment>
    );
}

export default DatesInfo;