import React, { useState, useEffect } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Modal, Delete, Button, Field, Label, Control, Input, Help, Icon } from "rbx";
import UsersService from "../../../../services/users";
import '../../../../styles/change-password.scss';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotificationModal from "../../../modals/notification-modal";

function ChangePasswordSecondStep(props) {
    const [fields, setFields] = useState({
        email: '',
        senha: '',
        senhaConf: '',
        token: ''
    })
    const [error, setError] = useState();
    const [fieldType, setFieldType] = useState('password');
    const [fieldIcon, setFieldIcon] = useState(faEye);


    useEffect(() => {
        setFields({
            ...fields,
            token: props.changeToken
        })
    }, [props.changeToken]);

    const checkPassword = (senha, senhaConf) => {
        if (senha === senhaConf) {
            return true;
        } else {
            setError('danger');
            return false;
        }
    }


    const handleChange = (e) => {
        setFields({
            ...fields,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }


    const handleClick = () => {
        if (checkPassword(fields.senha, fields.senhaConf)) {
            if (!props.showCode) {
                try {
                    UsersService.updatePassword(fields).then((response) => {
                        if (response.data === 'success') {
                            UsersService.login({ email: fields.email, senha: fields.senha });
                            props.setIsModalVisible(false);
                            props.setIsFirstStepVisible(false);
                            props.setNotificationText('Senha atualizada com sucesso!');
                            props.setIsNotificationVisible(true);
                            setFields({ email: '', senha: '', senhaConf: '', token: '' })
                        } else {
                            setError('danger');
                        }
                    })
                } catch (erro) {
                    setError('danger');
                    console.log(erro);
                }
            } else {
                try {
                    UsersService.updatePassword(fields).then((response) => {
                        if (response.data === 'success') {
                            props.setIsModalVisible(false);
                            props.setIsNotificationVisible(true);
                            setFields({ email: '', senha: '', senhaConf: '', token: '' })
                        } else {
                            setError('danger');
                        }
                    })
                } catch (erro) {
                    setError('danger');
                    console.log(erro);
                }

            }


        }
    }

    const handleShowPasswordClick = (e) => {
        e.preventDefault();
        if (fieldType === 'password') {
            setFieldType('text');
            setFieldIcon(faEyeSlash);
        }
        else {
            setFieldType('password');
            setFieldIcon(faEye);
        }
    }


    return (
        <Fragment>
            <Modal active={props.isModalVisible}>
                <Modal.Background />
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Informações para mudança de senha</Modal.Card.Title>
                        <Delete onClick={() => {
                            props.setIsModalVisible(false);
                            setFields({ email: '', senha: '', senhaConf: '', token: '' })
                        }} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <Field>
                            <Label>Email</Label>
                            <Control>
                                <Input
                                    type="email"
                                    name="email"
                                    value={fields.email}
                                    onChange={handleChange}
                                >
                                </Input>
                            </Control>

                        </Field>
                        <Field>
                            <Label>Senha</Label>
                            <Field kind='addons'>
                                <Control className='senha-input'>
                                    <Input
                                        color={error}
                                        type={fieldType}
                                        name="senha"
                                        value={fields.senha}
                                        onChange={handleChange}
                                    >
                                    </Input>
                                </Control>
                                <Control>
                                    <Button
                                        as='a' 
                                        onClick={handleShowPasswordClick}>
                                        <Icon size='small'>
                                            <FontAwesomeIcon icon={fieldIcon} />
                                        </Icon>
                                    </Button>
                                </Control>
                            </Field>
                        </Field>
                        <Field>
                            <Label>Confirme sua nova senha</Label>
                            <Field kind='addons'>
                                <Control className='senha-input'>
                                    <Input
                                        color={error}
                                        type={fieldType}
                                        name="senhaConf"
                                        value={fields.senhaConf}
                                        onChange={handleChange}
                                    >
                                    </Input>
                                </Control>
                                <Control>
                                    <Button
                                        as='a' 
                                        onClick={handleShowPasswordClick}>
                                        <Icon size='small'>
                                            <FontAwesomeIcon icon={fieldIcon} />
                                        </Icon>
                                    </Button>
                                </Control>
                            </Field>
                            {error
                                ? <Help color={error}>As senhas não coincidem</Help>
                                : ''}
                        </Field>
                        {props.showCode
                            ? <Field>
                                <Label>Código</Label>
                                <Control>
                                    <Input
                                        type="text"
                                        name="token"
                                        value={fields.token}
                                        onChange={handleChange}
                                    >
                                    </Input>
                                </Control>

                            </Field>
                            : ''}

                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        <Button
                            onClick={handleClick}
                            className='change-password-btn-conf'
                        >Confirmar</Button>
                        <Button onClick={() => {
                            props.setIsModalVisible(false)
                            setFields({ email: '', senha: '', senhaConf: '', token: '' })
                        }} className='change-password-btn-cancel'
                        >Cancelar</Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </Modal>
        </Fragment>
    );
}

export default ChangePasswordSecondStep;