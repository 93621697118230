import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Modal, Button, Content, Delete } from "rbx";
import '../../../styles/delete-student-model.scss';
import StudentsServices from "../../../services/students";
import { ButtonGroup } from "rbx/elements/button/button-group";

function DeleteStudentModal({ indexToDelete, items, setItems, setIndexToDelete, id }) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isButtonUnvisible, setIsButtonUnvisible] = useState(false);
    let provisionalItems = items;

    const handleDeleteStudentClick = (e) => {
        e.preventDefault();
        setIsModalVisible(false);
        StudentsServices.delete(indexToDelete).then(() => {
            let index = provisionalItems.findIndex((data) => {
                return data.id === indexToDelete;
            })

            provisionalItems.splice(index, 1);
            setItems(provisionalItems);
            setIndexToDelete('');

        })
    }

    useEffect(() =>{
        let role = localStorage.getItem('role');
        if(role !== '1' && role !== '2'){
            setIsButtonUnvisible(true);
        }
    }, [])

    return (
        <Fragment>
            <Button.Group align="right" id={id}>
                <Button
                    disabled={isButtonUnvisible}
                    className="delete-student-button"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsModalVisible(true);
                    }}
                > Apagar</Button>
            </Button.Group>

            <Modal active={isModalVisible}>
                <Modal.Background />
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Apagar aluno</Modal.Card.Title>
                        <Delete onClick={() => setIsModalVisible(false)} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <Content>
                            <p className="delete-student-text">Os dados do aluno serão apagados permanentemente. Deseja continuar?</p>
                        </Content>
                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        <Button
                            className="yes-delete"
                            onClick={handleDeleteStudentClick}
                        >Sim</Button>
                        <Button
                            onClick={() => setIsModalVisible(false)}
                            className='no-delete'>Não</Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </Modal>
        </Fragment>
    );
}

export default DeleteStudentModal;