import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Modal, Button, Box, Media, Image, Content, Icon, Level, Delete } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faRetweet } from "@fortawesome/free-solid-svg-icons";
import '../../../styles/delete-note-model.scss';
import NotesServices from "../../../services/notes";

function DeleteNoteModal({ noteToEdit, noteList, setNoteList }) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isButtonUnvisible, setIsButtonUnvisible] = useState(false);
    let provisionalNoteList = noteList;

    const handleDeleteClick = (e) => {
        if (noteToEdit.id) {
            NotesServices.delete(noteToEdit.id);
        }
        provisionalNoteList.splice(noteToEdit.noteIndex, 1);
        
        setNoteList(provisionalNoteList);

        setIsModalVisible(false);
    }

    useEffect(() =>{
        let role = localStorage.getItem('role');
        if(role !== '1' && role !== '2'){
            setIsButtonUnvisible(true);
        }
    }, [])
    return (
        <Fragment>
            <Button.Group align="right" className="delete-note-button-group">
                <Button
                    disabled={isButtonUnvisible}
                    className="delete-note-button"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsModalVisible(true);

                    }}
                > Apagar</Button>
            </Button.Group>
           
            <Modal active={isModalVisible}>
                <Modal.Background />
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Apagar observação</Modal.Card.Title>
                        <Delete onClick={() => setIsModalVisible(false)} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <Content>
                            <p className="delete-note-text">A nota será apagada permanentemente. Deseja continuar?</p>
                        </Content>
                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        <Button
                            className="yes-delete"
                            onClick={handleDeleteClick}>Sim</Button>
                        <Button
                            onClick={() => setIsModalVisible(false)}
                            className='no-delete'>Não</Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </Modal>
        </Fragment>
    );
}

export default DeleteNoteModal;