import React, { useState, useEffect } from "react";
import { Field, Label, Control, Input, Select, Icon, Help, Button } from "rbx";
import { Fragment } from "react/cjs/react.production.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import '../../../styles/change-password.scss';

function UserForm({ option, users, indexToEdit, setUsers, isDisabled,
    setIsDisabled, fields, setFields, error }) {
    const [fieldType, setFieldType] = useState('password');
    const [fieldIcon, setFieldIcon] = useState(faEye);


    useEffect(() => {
        if (option === 'edit-user') {
            console.log(users[indexToEdit]);
            setFields({
                ...users[indexToEdit]
            })
            setIsDisabled(true);
        }
        else if (option === 'edit-password') {
            setFields({
                ...users[indexToEdit],
                senha: '',
                senhaConf: ''
            })
        }
        else if (option === 'add-user') {
            setFields({
                nome: '',
                email: '',
                cargo: '1',
                senha: '',
                senhaConf: ''
            })
        }
    }, [option])

    const handleFieldsChange = (e) => {
        const { value, name } = e.currentTarget;
        setFields({
            ...fields,
            [name]: value
        })
    }

    const handleShowPasswordClick = () => {
        if (fieldType === 'password') {
            setFieldType('text');
            setFieldIcon(faEyeSlash);
        }
        else {
            setFieldType('password');
            setFieldIcon(faEye);
        }

    }

    return (
        <Fragment>
            {option === 'add-user' || option === 'edit-user'
                ? <Fragment>
                    <Field>
                        <Label>Nome</Label>
                        <Control>
                            <Input
                                type="text"
                                name='nome'
                                value={fields.nome}
                                onChange={handleFieldsChange}
                                disabled={isDisabled} />
                        </Control>
                    </Field>
                    <Field>
                        <Label>Email</Label>
                        <Control>
                            <Input
                                type="email"
                                name='email'
                                value={fields.email}
                                onChange={handleFieldsChange}
                                disabled={isDisabled} />
                        </Control>
                    </Field>
                    <Field>
                        <Label>Cargo</Label>
                        <Control>
                            <Select.Container fullwidth>
                                <Select
                                    name='cargo'
                                    value={fields.cargo}
                                    onChange={handleFieldsChange}
                                    disabled={isDisabled}>
                                    <Select.Option value={1}>Administrador</Select.Option>
                                    <Select.Option value={2}>Editor</Select.Option>
                                    <Select.Option value={3}>Visitante</Select.Option>
                                </Select>
                            </Select.Container>
                        </Control>
                    </Field>
                </Fragment>
                : ""}
            {option === 'add-user' || option === 'edit-password'
                ? <Fragment>
                    <Field>
                        <Label>Senha</Label>
                        <Field kind='addons'>
                            <Field className="senha-input">
                                <Control iconLeft>
                                    <Input
                                        color={error}
                                        type={fieldType}
                                        name='senha'
                                        value={fields.senha}
                                        onChange={handleFieldsChange} />
                                    <Icon size="small" align="left">
                                        <FontAwesomeIcon icon={faLock} />
                                    </Icon>
                                </Control>
                            </Field>
                            <Control>
                                <Button
                                    as='a'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleShowPasswordClick();
                                    }
                                    }>
                                    <Icon size='small'>
                                        <FontAwesomeIcon icon={fieldIcon} />
                                    </Icon>
                                </Button>
                            </Control>
                        </Field>
                    </Field>

                    <Field >
                        <Label>Confirme a senha</Label>
                        <Field kind='addons'>
                            <Field className="senha-input">
                                <Control iconLeft>
                                    <Input
                                        color={error}
                                        type={fieldType}
                                        name='senhaConf'
                                        value={fields.senhaConf}
                                        onChange={handleFieldsChange} />
                                    <Icon size="small" align="left">
                                        <FontAwesomeIcon icon={faLock} />
                                    </Icon>
                                </Control>
                                {error
                                    ? <Help color={error}>As senhas não coincidem</Help>
                                    : ''}
                            </Field>
                            <Control>
                                <Button
                                    as='a'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleShowPasswordClick();
                                    }
                                    }>
                                    <Icon size='small'>
                                        <FontAwesomeIcon icon={fieldIcon} />
                                    </Icon>
                                </Button>
                            </Control>
                        </Field>
                    </Field>

                </Fragment>
                : ""}
        </Fragment>
    );
}

export default UserForm;