import React from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Control, Field, Icon, Input, Label, Help } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faTags, faWallet } from "@fortawesome/free-solid-svg-icons";



function PaymentInfo(props) {
    const handleFieldsChange = (e) => {
        props.setFields({
            ...props.fields,
            [e.currentTarget.name]: e.currentTarget.value
        })
    };
    return (
        <Fragment>
            <Field horizontal>
                <Field.Label>
                    <Label className="has-text-custom-purple">Informações de pagamento</Label>
                </Field.Label>
                <Field.Body>
                    <Field>
                        <Control expanded iconLeft>
                            <Input
                                placeholder="Valor Pago"
                                maskPlaceholder=""
                                type="text"
                                name="valor_pago"
                                value={props.fields.valor_pago}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faDollarSign} />
                            </Icon>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Valor pago</Help>
                            : ""}
                    </Field>
                    <Field>
                        <Control expanded iconLeft >
                            <Input
                                placeholder="Pagamento"
                                type="text"
                                name="pagamento"
                                value={props.fields.pagamento}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faWallet} />
                            </Icon>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Pagamento</Help>
                            : ""}
                    </Field>
                    <Field>
                        <Control expanded iconLeft >
                            <Input
                                placeholder="Bônus"
                                type="text"
                                name="bonus"
                                value={props.fields.bonus}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faTags} />
                            </Icon>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Bônus</Help>
                            : ""}
                    </Field>
                </Field.Body>
            </Field>
            <Field horizontal>
                <Field.Label />
                <Field.Body>
                    <Field>
                        <Control expanded iconLeft>
                            <Input
                                placeholder="Valor dois anos"
                                type="text"
                                name="valor_dois_anos"
                                value={props.fields.valor_dois_anos}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faDollarSign} />
                            </Icon>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Valor dois anos</Help>
                            : ""}
                    </Field>
                    <Field>
                        <Control expanded iconLeft>
                            <Input
                                placeholder="Valor da renovação"
                                type="text"
                                name="valor_da_renovacao"
                                value={props.fields.valor_da_renovacao}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faDollarSign} />
                            </Icon>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Valor da renovação</Help>
                            : ""}
                    </Field>
                </Field.Body>
            </Field>
        </Fragment>
    );
}

export default PaymentInfo;