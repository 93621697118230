import Api from "./api";

const UsersService = {
    register: async (params) => Api.post('/users/register', params),
    login: async (params) => {
        try {
            const response = await Api.post('/users/login', JSON.stringify(params));
            localStorage.setItem('user', response.data.nome);
            localStorage.setItem('role', response.data.cargo);
            return response;
        } catch (error) {
            return 'unauthorized';
        }

    },
    logout: () => {
        Api.post('/users/logout');
        localStorage.removeItem('user');
        localStorage.removeItem('role');
    },
    get: async () => {
        const response = await Api.get('/users');
        return response;
    },
    patch: async (params) => {
        Api.patch('/users', params);
    },
    delete: async (id) => {
        Api.delete(`/users/${id}`);
    },
    getToken: async (params) => {
        try {
            const response = await Api.post('/users/password', params);
            return response;
        } catch (error) {
            return 'unauthorized';
        }
    },
    updatePassword: async (params) => {
        const response = await Api.patch('/users/password', params);
        return response;
    },
    getTokenPerId: async (id, params) => {
        const response = await Api.post(`users/password/${id}`, params);
        return response;
    }
}

export default UsersService;