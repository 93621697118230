import React from "react";
import {Fragment} from "react/cjs/react.production.min";
import {Control, Field, Help, Icon, Input, Label, Select, Textarea} from "rbx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBookOpen,
    faCalendarAlt,
    faClipboardList,
    faGraduationCap,
    faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";


function CourseInfo(props) {
    const handleFieldsChange = (e) => {
        {
            props.setFields({
                ...props.fields,
                [e.currentTarget.name]: e.currentTarget.value
            })
        }
    };

    return (
        <Fragment>
            <Field horizontal>
                <Field.Label>
                    <Label className="has-text-custom-purple">Informações quanto ao curso</Label>
                </Field.Label>
                <Field.Body>
                    <Field>
                        <Control expanded iconLeft >
                            <Input
                                placeholder="Status"
                                type="text"
                                name="status"
                                value={props.fields.status}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </Icon>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Status</Help>
                            : ""}
                    </Field>
                    <Field>
                        <Control expanded iconLeft >
                            <Input
                                placeholder="Nível"
                                type="text"
                                name="nivel"
                                value={props.fields.nivel}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faBookOpen} />
                            </Icon>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Nível</Help>
                            : ""}
                    </Field>
                    <Field>
                        <Control expanded iconLeft>
                            <InputMask
                                className='input'
                                mask='99'
                                maskChar={null}
                                placeholder="Turma"
                                type="text"
                                name="turma"
                                value={props.fields.turma}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faGraduationCap} />
                            </Icon>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Turma</Help>
                            : ""}
                    </Field>
                </Field.Body>
            </Field>

            <Field horizontal>
                <Field.Label>

                </Field.Label>
                <Field.Body>
                    <Field>
                        <Control expanded iconLeft >
                            <Input
                                placeholder="Nota necessária"
                                type="text"
                                name="nota_necessaria"
                                value={props.fields.nota_necessaria}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faClipboardList} />
                            </Icon>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Nota necessária</Help>
                            : ""}
                    </Field>

                    <Field>
                        <Control expanded iconLeft>
                            <InputMask
                                placeholder="Data da prova"
                                className="input"
                                mask="99/99/9999"
                                type="text"
                                name="data_da_prova"
                                value={props.fields.data_da_prova}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible}
                            />
                            <Icon size="small" align="left">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </Icon>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Data da prova</Help>
                            : ""}
                    </Field>
                    <Field>
                        <Control expanded iconLeft>
                            <Input
                            name="prova"
                            type="text"
                            placeholder="Prova"
                            value={props.fields.prova}
                            onChange={handleFieldsChange}
                            disabled={props.isFieldsInvisible}
                            >
                            </Input>
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Prova</Help>
                            : ""}
                    </Field>
                </Field.Body>
            </Field>
            <Field horizontal>
                <Field.Label />
                <Field.Body>
                    <Field>
                        <Control>
                            <Textarea
                                placeholder="Plano de estudos"
                                name="plano_de_estudos"
                                value={props.fields.plano_de_estudos}
                                onChange={handleFieldsChange}
                                disabled={props.isFieldsInvisible} />
                        </Control>
                        {props.isFieldsInvisible || props.fields.id !== ''
                            ? <Help>Plano de estudos</Help>
                            : ""}
                    </Field>
                </Field.Body>
            </Field>
        </Fragment>
    );
}

export default CourseInfo;