import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Modal, Button, Icon, Delete, Content, Title, Panel } from 'rbx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../../styles/custom-modal.scss';
import { faCommentDots, faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import SecondModal from "../second-modal";
import DeleteNoteModal from "../delete-note-modal";

function FirstModal({ id = 'modal', noteList, setNoteList, fields, setIsFieldsInvisible, setFields, adjustDataBack }) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isNoteEdit, setIsNoteEdit] = useState(false);
    const [noteToEdit, setNoteToEdit] = useState();


    const handleOutsideClick = (e) => {
        if (e.target.id === id) setIsModalVisible(false);
    }

    const handleNoteClick = (e) => {
        setNoteToEdit({
            ...noteList[e.currentTarget.getAttribute('id')],
            noteIndex: e.currentTarget.getAttribute('id')
        });

        if (e.target === e.currentTarget) {
            setIsNoteEdit(true);
        }
    }

    return (
        <Fragment>
            <Button onClick={(e) => {
                e.preventDefault();
                setIsModalVisible(true);
                if (fields.id !== '') { setNoteList(fields.observacoes); }

            }}
                className="comments-btn"
            >
                <Icon size="small" align="left">
                    <FontAwesomeIcon icon={faCommentDots} />
                </Icon>
                <span>Observações</span>
            </Button>
            <Modal active={isModalVisible}>
                <Modal.Background onClick={handleOutsideClick} id='modal' />
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Observações</Modal.Card.Title>
                        <Delete onClick={() => setIsModalVisible(false)} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <Content>
                            {noteList !== undefined
                                ? noteList.length < 1
                                    ? <Title className='modals-title' >Não há observações</Title>
                                    : <Panel>
                                        {noteList.map((note, index) => {
                                            return <Panel.Block
                                                as="a"
                                                active
                                                id={index}
                                                key={index}
                                                onClick={handleNoteClick}>
                                                <Panel.Icon>
                                                    <FontAwesomeIcon icon={faCommentAlt} />
                                                </Panel.Icon>
                                                Observação {index + 1}
                                                <DeleteNoteModal
                                                    className='delete-note-button'
                                                    noteToEdit={noteToEdit} 
                                                    noteList={noteList}
                                                    setNoteList={setNoteList}/>
                                            </Panel.Block>

                                        })}
                                    </Panel>
                                : <Title>Não há observações</Title>}
                        </Content>
                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        <SecondModal
                            noteList={noteList}
                            setNoteList={setNoteList}
                            setIsFieldsInvisible={setIsFieldsInvisible}
                            fields={fields}
                            setFields={setFields}
                            adjustDataBack={adjustDataBack}
                            isNoteEdit={isNoteEdit}
                            setIsNoteEdit={setIsNoteEdit}
                            noteToEdit={noteToEdit}
                        />
                    </Modal.Card.Foot>
                </Modal.Card>
            </Modal>
        </Fragment>
    );
}

export default FirstModal;