import React from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Field, Control, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function FormButton(props){
    return(
        <Fragment>
            <Field horizontal>
                    <Field.Label/>
                    <Field.Body>
                        <Field>
                            <Control>
                                <Button
                                    disabled={props.isButtonUnvisible}
                                    className="right-screen-button"
                                >
                                    <Icon size="small" align="left">
                                        <FontAwesomeIcon icon={props.icon}/>
                                    </Icon>
                                    <span>{props.text}</span>
                                    
                                </Button>
                                
                            </Control>
                        </Field>
                    </Field.Body>
                </Field>
        </Fragment>
    );
}

export default FormButton;