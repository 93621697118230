import React, { useState, useEffect } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Modal, Delete, Button } from "rbx";
import UserForm from "../user-form";
import UsersService from "../../../services/users";
import '../../../styles/change-password.scss'

function AddUserModal({ isModalVisible, setIsModalVisible, option, users, indexToEdit, setUsers }) {
    const [isDisabled, setIsDisabled] = useState(false);
    const [error, setError] = useState();
    const [fields, setFields] = useState({
        nome: '',
        email: '',
        cargo: '1',
        senha: '',
        senhaConf: ''
    })

    let provisionalUsers = users;

    const checkPassword = (senha, senhaConf) => {
        if (senha === senhaConf) {
            console.log('true');
            return true;
        } else {
            setError('danger');
            console.log('false');
            return false;
        }
    }

    const handleRegisterClick = () => {
        let userToRegister = fields;
        userToRegister.cargo = Number(userToRegister.cargo);
        if (checkPassword(userToRegister.senha, userToRegister.senhaConf)) {
            if (!userToRegister.id) {
                try {
                    UsersService.register(userToRegister).then((response) => {
                        userToRegister.id = response.data.id;
                        setUsers([...users, userToRegister]);
                        setError();
                    })
                } catch (error) {
                    console.log(error);
                }

            } else {
                let userToUpdate = fields;
                userToRegister.cargo = Number(userToUpdate.cargo);
                provisionalUsers[indexToEdit] = userToUpdate;
                try {
                    UsersService.patch(userToUpdate).then(() => {
                        setUsers(provisionalUsers);
                    })
                } catch (error) {
                    console.log(error);
                }
            }

            setIsModalVisible(false);
        }

    }
    function handleOutsideClick(e){
        if(e.target.id === "modal") setIsModalVisible(false);
    }


    return (
        <Fragment>
            <Modal active={isModalVisible}>
                <Modal.Background id="modal" onClick={handleOutsideClick} />
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Dados do usuário</Modal.Card.Title>
                        <Delete onClick={() => {
                            setIsModalVisible(false);
                            setIsDisabled(false);
                        }} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <UserForm
                            option={option}
                            users={users}
                            indexToEdit={indexToEdit}
                            setUsers={setUsers}
                            isDisabled={isDisabled}
                            setIsDisabled={setIsDisabled}
                            fields={fields}
                            setFields={setFields}
                            error={error} />
                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        {isDisabled
                            ? <Button
                                className="first-step-confirm"
                                onClick={() => {
                                    setIsDisabled(false);
                                }}>Editar</Button>
                            : <Button className='first-step-confirm' onClick={handleRegisterClick}>Salvar</Button>}

                        <Button 
                            className="first-step-cancel"
                            onClick={() => {
                            setIsModalVisible(false);
                            setIsDisabled(false);
                        }} >Cancelar</Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </Modal>
        </Fragment>
    );
}

export default AddUserModal;