import React, { Fragment } from "react/cjs/react.production.min";
import { Modal, Delete, Button } from "rbx";
import { useState, useEffect } from "react";
import ClassesServices from "../../services/classes";
import StudentsServices from "../../services/students";


function Filters({ id = "modal", isModalVisible, setIsModalVisible, setQuery,
    page, searchBarInput, removeFilters, setRemoveFilters }) {
    const [testMonth, setTestMonth] = useState("");
    const [studentClass, setStudentClass] = useState("");
    const [renewDateBegin, setRenewDateBegin] = useState("");
    const [renewDateEnd, setRenewDateEnd] = useState("");
    const [activityDateBegin, setActivityDateBegin] = useState("");
    const [activityDateEnd, setActivityDateEnd] = useState("");
    const [classes, setClasses] = useState([""]);
    const [activeSelected, setActiveSelected] = useState("ignore");
    const [approvedSelected, setApprovedSelected] = useState("ignore");
    const [waitingSelected, setWaitingSelected] = useState("ignore");
    const [renewedSelected, setRenewedSelected] = useState("ignore");

    const testMonthList = ["", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    useEffect(() => {
        let classList = [""];
        ClassesServices.getClasses().then((response) => {
            response.map((item) => {
                classList.push(item);
            })
            setClasses(classList);
        });
    }, []);

    useEffect(() => {
        let query = buildQuery(page, searchBarInput);

        setQuery(query);
    }, [page, searchBarInput]);

    useEffect(() => {
        console.log(removeFilters);
        if (removeFilters) {
            console.log(removeFilters);
            setAllDefault();
            setRemoveFilters(false);
        }
    }, [removeFilters])

    return (
        <Fragment>
            <Modal active={isModalVisible}>
                <Modal.Background onClick={handleOutsideClick} id="modal" />
                <Modal.Card>
                    <Modal.Card.Head>

                        <Delete onClick={() => {
                            setIsModalVisible(false);
                        }} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <div className="columns">
                            <div className="column">
                                <h1 className="mb-2">Alunos ativos?</h1>
                                <div className="control">
                                    <label className="radio" id="active-students">
                                        <input type="radio" name="active-students" value="yes" checked={activeSelected === "yes"} onChange={handleActiveRadioChange} />
                                        Sim
                                    </label>
                                    <label className="radio">
                                        <input type="radio" name="active-students" value="no" checked={activeSelected === "no"} onChange={handleActiveRadioChange} />
                                        Não
                                    </label>
                                    <label className="radio">
                                        <input type="radio" name="active-students" value="ignore" checked={activeSelected === "ignore"} onChange={handleActiveRadioChange} />
                                        Ignorar
                                    </label>
                                </div>
                            </div>
                            <div className="column">
                                <h1>Alunos aprovados?</h1>
                                <div className="control">
                                    <label className="radio" id="approved-students">
                                        <input type="radio" name="approved-students" value="yes" checked={approvedSelected === "yes"} onChange={handleApprovedRadioChange} />
                                        Sim
                                    </label>
                                    <label className="radio">
                                        <input type="radio" name="approved-students" value="no" checked={approvedSelected === "no"} onChange={handleApprovedRadioChange} />
                                        Não
                                    </label>
                                    <label className="radio">
                                        <input type="radio" name="approved-students" value="ignore" checked={approvedSelected === "ignore"} onChange={handleApprovedRadioChange} />
                                        Ignorar
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <h1>Esperando por teste?</h1>
                                <div className="control">
                                    <label className="radio" id="waiting-students">
                                        <input type="radio" name="waiting-students" value="yes" checked={waitingSelected === "yes"} onChange={handleWaitingRadionChange} />
                                        Sim
                                    </label>
                                    <label className="radio">
                                        <input type="radio" name="waiting-students" value="no" checked={waitingSelected === "no"} onChange={handleWaitingRadionChange} />
                                        Não
                                    </label>
                                    <label className="radio">
                                        <input type="radio" name="waiting-students" value="ignore" checked={waitingSelected === "ignore"} onChange={handleWaitingRadionChange} />
                                        Ignorar
                                    </label>
                                </div>
                            </div>
                            <div className="column">
                                <h1>Alunos que renovaram?</h1>
                                <div className="control">
                                    <label className="radio" id="renewed-students">
                                        <input type="radio" name="renewed-students" value="yes" checked={renewedSelected === "yes"} onChange={handleRenewedRadioChange} />
                                        Sim
                                    </label>
                                    <label className="radio">
                                        <input type="radio" name="renewed-students" value="no" checked={renewedSelected === "no"} onChange={handleRenewedRadioChange} />
                                        Não
                                    </label>
                                    <label className="radio">
                                        <input type="radio" name="renewed-students" value="ignore" checked={renewedSelected === "ignore"} onChange={handleRenewedRadioChange} />
                                        Ignorar
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <h1>Mês da prova</h1>
                                <div className="select">
                                    <select
                                        value={testMonth}
                                        name="new-test-month"
                                        onChange={(e) => {
                                            handleTestMonthChange(e);
                                        }}>
                                        {testMonthList.map((item, index) => {
                                            return <option value={item} key={index}>{item}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="column">
                                <h1>Turma</h1>
                                <div className="columns">
                                    <div className="column">
                                        <div className="select">
                                            <select
                                                value={studentClass}
                                                name="student-class"
                                                onChange={(e) => {
                                                    handleStudentClassChange(e);
                                                }}>
                                                {classes.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h1 className="mb-2">Data de renovação</h1>
                        <div className="columns">
                            <div className="column">
                                <label>
                                    Início
                                    <input
                                        type="date"
                                        name="renew-date-begin"
                                        value={renewDateBegin}
                                        onChange={(e) => {
                                            handleRenewDateBeginChange(e);
                                        }} />
                                </label>
                            </div>
                            <div className="column">
                                <label>
                                    Fim
                                    <input
                                        type="date"
                                        name="renew-date-end"
                                        value={renewDateEnd}
                                        onChange={(e) => {
                                            handleRenewDateEndChange(e);
                                        }} />
                                </label>
                            </div>
                        </div>
                        <h1 className="mb-2">Data de atividade</h1>
                        <div className="columns">
                            <div className="column">
                                <label>
                                    Início
                                    <input
                                        type="date"
                                        name="activity-date-begin"
                                        value={activityDateBegin}
                                        onChange={(e) => {
                                            handleActivityDateBeginChange(e);
                                        }} />
                                </label>
                            </div>
                            <div className="column">
                                <label>
                                    Fim
                                    <input
                                        type="date"
                                        name="activity-date-end"
                                        value={activityDateEnd}
                                        onChange={(e) => {
                                            handleActivityDateEndChange(e);
                                        }} />
                                </label>
                            </div>
                        </div>

                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        <Button
                            onClick={() => {
                                let queryToFilters = buildQuery(page, searchBarInput);
                                insertQuery(queryToFilters);
                            }}
                            className="notification-button"
                        >Aplicar</Button>
                        <a href={`/api/students/reports?${buildQuery()}`} >
                            <Button> Gerar Relatório </Button>
                        </a>
                    </Modal.Card.Foot>
                </Modal.Card>
            </Modal>
        </Fragment>
    );

    function buildQuery(page, searchBarInput) {

        let expression = "";

        if (page !== undefined) {
            expression = `page=${page}&size=15`;
        }
        //=================Alunos ativos===================================
        if (activeSelected === "yes") expression += "&active=true";
        else if (activeSelected === "no") expression += "&active=false";
        else if (activeSelected === "ignore") expression += "";

        //=================Alunos aprovados===================================
        if (approvedSelected === "yes") expression += "&approved=true";
        else if (approvedSelected === "no") expression += "&approved=false";
        else if (approvedSelected === "ignore") expression += "";


        //=================Esperando por teste===================================
        if (waitingSelected === "yes") expression += "&waiting=true";
        else if (waitingSelected === "no") expression += "&waiting=false";
        else if (waitingSelected === "ignore") expression += "";

        //=================Alunos que renovaram===================================
        if (renewedSelected === "yes") expression += "&renewed=true";
        else if (renewedSelected === "no") expression += "&renewed=false";
        else if (renewedSelected === "ignore") expression += "";

        //=================Mês da prova===================================
        if (testMonth !== "") expression += `&month=${testMonth}`;

        //=================Turma===================================
        if (studentClass !== "") expression += `&class=${studentClass}`;

        //=================Data de renovação===================================
        if (renewDateBegin !== "" && renewDateEnd === "") {
            const formatedDate = renewDateBegin.split("-").reverse().join("-");
            expression += `&renewDate=${formatedDate}_`;
        }
        else if (renewDateBegin === "" && renewDateEnd !== "") {
            const formatedDate = renewDateEnd.split("-").reverse().join("-");
            expression += `&renewDate=_${formatedDate}`;
        }
        else if (renewDateBegin !== "" && renewDateEnd !== "") {
            const formatedBeginDate = renewDateBegin.split("-").reverse().join("-");
            const formatedEndDate = renewDateEnd.split("-").reverse().join("-");
            expression += `&renewDate=${formatedBeginDate}_${formatedEndDate}`;
        }

        //=================Data de atividade===================================
        if (activityDateBegin !== "" && activityDateEnd === "") {
            const formatedDate = activityDateBegin.split("-").reverse().join("-");
            expression += `&activityDate=${formatedDate}_`;
        }
        else if (activityDateBegin === "" && activityDateEnd !== "") {
            const formatedDate = activityDateEnd.split("-").reverse().join("-");
            expression += `&activityDate=_${formatedDate}`;
        }
        else if (activityDateBegin !== "" && activityDateEnd !== "") {
            const formatedBeginDate = activityDateBegin.split("-").reverse().join("-");
            const formatedEndDate = activityDateEnd.split("-").reverse().join("-");
            expression += `&activityDate=${formatedBeginDate}_${formatedEndDate}`;
        }

        //=================Nome===================================
        if (searchBarInput !== undefined && searchBarInput.length > 2) {
            const formatedName = searchBarInput.replace(" ", "%20");
            expression += `&name=${formatedName}`;
        }

        return expression;
    }

    function handleTestMonthChange(e) {
        setTestMonth(e.target.value);
    }

    function handleStudentClassChange(e) {
        setStudentClass(e.target.value);
    }

    function handleRenewDateBeginChange(e) {
        setRenewDateBegin(e.target.value);
    }

    function handleRenewDateEndChange(e) {
        setRenewDateEnd(e.target.value);
    }

    function handleActivityDateBeginChange(e) {
        setActivityDateBegin(e.target.value);
    }

    function handleActivityDateEndChange(e) {
        setActivityDateEnd(e.target.value);
    }

    function insertQuery(query) {
        setQuery(query);
    }
    function handleActiveRadioChange(e) {
        setActiveSelected(e.target.value);
    }
    function handleApprovedRadioChange(e) {
        setApprovedSelected(e.target.value);
    }
    function handleWaitingRadionChange(e) {
        setWaitingSelected(e.target.value);
    }
    function handleRenewedRadioChange(e) {
        setRenewedSelected(e.target.value);
    }

    function handleOutsideClick(e) {
        if (e.target.id === "modal") setIsModalVisible(false);
    }
    function setAllDefault() {
        setActiveSelected("ignore");
        setApprovedSelected("ignore");
        setWaitingSelected("ignore");
        setRenewedSelected("ignore");
        setTestMonth("");
        setStudentClass("");
        setRenewDateBegin("");
        setRenewDateEnd("");
        setActivityDateBegin("");
        setActivityDateEnd("");
    }

    
}

export default Filters;
