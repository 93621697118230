import React, { useState, useEffect } from "react";
import { Container, Icon, Navbar } from "rbx";
import LogoImage from "../../../assets/images/logo-2.png";
import "../../../styles/header-logged.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordFirstStep from "../../auth/change-password/first-step";
import NotificationModal from "../../modals/notification-modal";


function HeaderLogged() {
    let user = localStorage.getItem('user');
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <Navbar>
            <Container fluid>
                <Navbar.Brand>
                    <img src={LogoImage} alt="logo" />
                    <Navbar.Burger
                        className="navbar-burger burger"
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navbar-menu">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </Navbar.Burger>
                </Navbar.Brand>

                <Navbar.Menu id="navbar-menu">
                    <Navbar.Segment as="div" className="navbar-item navbar-end" align="right">
                        <Navbar.Item dropdown>
                            <Navbar.Link>
                                <Container className="user-info" >
                                    <p>{user}</p>
                                    <Icon>
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </Icon>
                                </Container>
                            </Navbar.Link>
                            <Navbar.Dropdown>
                                <Navbar.Item onClick={() => {
                                    setIsModalVisible(true);
                                }}>Trocar senha
                                </Navbar.Item>
                            </Navbar.Dropdown>
                        </Navbar.Item>
                    </Navbar.Segment>
                </Navbar.Menu>
            </Container>
            <ChangePasswordFirstStep
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                root='header'
            />
        </Navbar>
    );
}

export default HeaderLogged;