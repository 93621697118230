import React from 'react';
import './App.scss';
import {Fragment} from 'react/cjs/react.production.min';
import Switch from './switch';

function App(){
  document.title = 'Gerenciamento de alunos - Eli Sato';
  
  return(
    <Fragment>
      <Switch />
    </Fragment>
  );
}

export default App;
