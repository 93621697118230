import React, { useState, useEffect } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Panel, Button, Icon, Column } from 'rbx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import UsersService from "../../services/users";
import '../../styles/admin-tools.scss';
import AddUserModal from "./add-user-modal";
import { ColumnGroup } from "rbx/grid/columns/column-group";
import DeleteUserModal from "./delete-user-modal";
import ChangePasswordFirstStep from "../auth/change-password/first-step";

function AdminTools({optionsHistoric, setOptionsHistoric}) {
    const [users, setUsers] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [option, setOption] = useState('');
    const [indexToEdit, setIndexToEdit] = useState();
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isChangeVisible, setIsChangeVisible] = useState(false);
    const [isButtonUnvisible, setIsButtonUnvisible] = useState(false);
    

    const getUsersList = async () => {
        try {
            return await UsersService.get();
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = (e) => {
        if (e.currentTarget.getAttribute('id') === 'user' && e.target.getAttribute('id') === 'user') {
            setIndexToEdit(e.currentTarget.getAttribute('value'));
            setOption('edit-user');
            setIsModalVisible(true);

        } else if (e.currentTarget.getAttribute('id') === 'add-user') {
            setOption('add-user')
            setIsModalVisible(true);
        } else if (e.target.getAttribute('id') === 'edit-password') {
            setIndexToEdit(users[e.currentTarget.getAttribute('value')].id);
            setOption('edit-password');
            setIsChangeVisible(true);
        } else if (e.target.getAttribute('id') === 'delete-user') {
            setIndexToEdit(e.currentTarget.getAttribute('value'));

        }
    }

    useEffect(() => {
        getUsersList().then((response) => {
            setUsers(response.data);
        });

        return () => {
            const historic = optionsHistoric;
            historic.push("studentRegistration");
            setOptionsHistoric(historic);
        }

        
    }, []);

    useEffect(() => {

    }, [users]);

    useEffect(() =>{
        let role = localStorage.getItem('role');

        if(role !== '1'){
            setIsButtonUnvisible(true);
        }
    }, [])

    return (
        <Fragment>
            <Panel className="panel-users-list">
                <Panel.Heading>Usuários</Panel.Heading>
                {users.map((item, index) => {
                    if (item.cargo !== 0) {
                        return (
                            <Panel.Block
                                as="a"
                                active
                                id='user'
                                value={index}
                                key={index}
                                onClick={handleClick}
                            >
                                <Column.Group breakpoint="mobile" className="users-columns">
                                    <Column size="one-third" narrow>
                                        <ColumnGroup breakpoint="mobile">
                                            <Column size={2}>
                                                <Panel.Icon className="user-icon" id='user'>
                                                    <FontAwesomeIcon icon={faUser} />
                                                </Panel.Icon>
                                            </Column>
                                            <Column id='user'>
                                                <div id='user'>
                                                    {item.nome}
                                                </div>
                                            </Column>
                                        </ColumnGroup>
                                    </Column>
                                    <Column id='user'>
                                        <Button.Group align='right' id='user'>
                                            <Button
                                                disabled={isButtonUnvisible}
                                                className="add-user-button"
                                                id='edit-password'>Alterar senha</Button>
                                            <Button
                                                disabled={isButtonUnvisible}
                                                className="delete-user-button"
                                                id='delete-user'
                                                onClick={(e) => {
                                                    setIsDeleteModalVisible(true);
                                                }}>Apagar</Button>
                                        </Button.Group>
                                    </Column>
                                </Column.Group>
                            </Panel.Block>);
                    }
                })}
            </Panel>
            <AddUserModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                option={option}
                users={users}
                setUsers={setUsers}
                indexToEdit={indexToEdit} />
            <DeleteUserModal
                isDeleteModalVisible={isDeleteModalVisible}
                setIsDeleteModalVisible={setIsDeleteModalVisible}
                users={users}
                setUsers={setUsers}
                indexToEdit={indexToEdit}
            />
            <Button
                className="add-user-button"
                id='add-user'
                onClick={handleClick}
            >
                <Icon size="small" align="left">
                    <FontAwesomeIcon icon={faUserPlus} />
                </Icon>
                <span>Adicionar novo usuário</span></Button>
            <ChangePasswordFirstStep
                isModalVisible={isChangeVisible}
                setIsModalVisible={setIsChangeVisible}
                root='admin'
                indexToEdit={indexToEdit} />
        </Fragment>
    );
}

export default AdminTools;