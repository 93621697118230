import Api from "./api";

const NotesServices = {
    post : async (params) =>{
        const response = await Api.post('/notes', params);
        return response;
    },
    update : async(params) =>{
        params.autor = null
        params.data = null
        Api.patch('/notes', params);
    },
    delete : async(id) =>{
        Api.delete(`/notes/${id}`);
    }
}

export default NotesServices;