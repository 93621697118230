import React, {Fragment, useEffect, useState} from "react";
import {Button, Delete, Modal, Textarea, Title} from 'rbx';
import moment from 'moment';
import NotesServices from "../../../services/notes";
import StudentsServices from "../../../services/students";
import '../../../styles/custom-modal.scss';


function SecondModal({
                         id = 'second-modal', noteList, setNoteList, setIsFieldsInvisible,
                         fields, setFields, adjustDataBack, isNoteEdit, setIsNoteEdit, noteToEdit
                     }) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [note, setNote] = useState({
        id_estudante: '',
        data: moment().format('DD/MM/YY,HH:mm'),
        texto: ''
    });
    const [isEditDisabled, setIsEditDisabled] = useState(false);
    const [isButtonUnvisible, setIsButtonUnvisible] = useState(false);
    let provisionalNoteList = noteList;


    useEffect(() => {
        provisionalNoteList = noteList;
    }, [noteList])

    useEffect(() => {
        if (fields.id !== '') {
            setNote({
                ...note,
                id_estudante: fields.id
            });
        }
    }, [])

    useEffect(() =>{
        let role = localStorage.getItem('role');
        if(role !== '1' && role !== '2'){
            setIsButtonUnvisible(true);
        }
    }, [])

    useEffect(() => {
        console.log(noteToEdit)
        console.log("a");
        if (isNoteEdit === true) {
            setIsModalVisible(true);
            setIsEditDisabled(true);
            const [data, hora] = noteToEdit.data.split(",");
            if (fields.id) {
                setNote({
                    autor : noteToEdit.autor.nome,
                    id: noteToEdit.id,
                    hora : hora,
                    data: data,
                    texto: noteToEdit.texto
                })
            }
            else {
                setNote({
                    noteIndex: noteToEdit.noteIndex,
                    id_estudante: noteToEdit.id_estudante,
                    hora : hora,
                    data: data,
                    texto: noteToEdit.texto
                })
            }
        }
    }, [isNoteEdit])

    const handleNoteChange = (e) => {
        setNote({
            ...note,
            [e.currentTarget.name]: e.currentTarget.value
        })
    }

    const handleAddClick = () => {
        setIsModalVisible(false);
        setIsFieldsInvisible(false);
        setIsEditDisabled(false);

        if (noteList !== undefined && !note.id && isNoteEdit !== true) {
            setNoteList([...noteList, note]);
            setNote({
                data: moment().format('YY/MM/DD,HH:mm'),
                id_estudante: fields.id,
                texto: ''
            });
        } else if (!note.id && isNoteEdit !== true) {
            setNoteList([note]);
            setNote({
                data: moment().format('YY/MM/DD,HH:mm'),
                id_estudante: fields.id,
                texto: ''
            });
        }

        setIsNoteEdit(false);

        if (note.id) {
            NotesServices.update(note).then(() => {
                provisionalNoteList[noteToEdit.noteIndex].texto = note.texto;
                setNoteList(provisionalNoteList);
            })

            setNote({
                data: moment().format('YY/MM/DD,HH:mm'),
                id_estudante: fields.id,
                texto: ''
            });
        } else if (fields.id !== '') {
            NotesServices.post(note).then(() => {
                StudentsServices.getPerId(fields.id).then((response) => {
                    adjustDataBack(response.data).then(() => {
                        setFields({ ...response.data });
                        setNoteList(response.data.observacoes);
                    })
                })
                setNote({
                    data: moment().format('YY/MM/DD,HH:mm'),
                    id_estudante: fields.id,
                    texto: ''
                });
            })
        } else if (provisionalNoteList !== undefined && isNoteEdit === true) {
            console.log(note);
            console.log(provisionalNoteList);
            provisionalNoteList[note.noteIndex].texto = note.texto;
            setNoteList(provisionalNoteList);

            setNote({
                data: moment().format('YY/MM/DD,HH:mm'),
                id_estudante: fields.id,
                texto: ''
            });
        }
    }

    const handleOutsideClick = (e) => {
        if (e.target.id === id) setIsModalVisible(false);
    }

    const handleClose = () => {
        setIsModalVisible(false);
        setIsNoteEdit(false);
        setIsEditDisabled(false);
        setNote({
            data: moment().format('YY/MM/DD,HH:mm'),
            id_estudante: fields.id,
            texto: ''
        });
    }

    return (
        <Fragment>
            <Button
                className="add-note-button"
                onClick={(e) => {
                    e.preventDefault();
                    setIsModalVisible(true);
                }}
            > Adicionar observação</Button>
            <Modal active={isModalVisible}>
                <Modal.Background onClick={handleOutsideClick} id='modal' />
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Adicionar observação</Modal.Card.Title>
                        <Delete onClick={handleClose} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        {note.autor
                            ? <Title>Autor: {note.autor}</Title>
                            : ''}
                        {console.log(note)}
                        <Title>Data: {note.data.split(",")[0]}</Title>
                        {note.hora !== undefined
                            ? <Title>Horário: {note.hora}</Title>
                            : ""}
                        <Textarea
                            name='texto'
                            value={note.texto}
                            onChange={handleNoteChange}
                            disabled={isEditDisabled} />
                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        {isEditDisabled
                            ? <Button
                                disabled={isButtonUnvisible} 
                                className="add-note-button" 
                                onClick={() => {
                                    setIsEditDisabled(false)
                            }}>Editar</Button>
                            : <Button className="add-note-button" onClick={handleAddClick}>Salvar</Button>}

                        <Button className="comments-btn" onClick={handleClose}>Cancelar</Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </Modal>
        </Fragment>
    )
}

export default SecondModal;