import React from 'react';
import { Outlet} from 'react-router-dom';
import LoginScreen from '../../../screens/auth/login';
import { Navigate } from "react-router-dom";

const PrivateRouter = ({ component: Component, ...rest }) => {
    return localStorage.getItem('user') ? <Outlet/> : <Navigate to={{ pathname: "/" }} />
}

export default PrivateRouter;

