import { Column } from "rbx";
import React, { useState, useEffect } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faStar } from "@fortawesome/free-solid-svg-icons";
import { Icon, Box, Content, Level} from "rbx";
import "../../styles/activity.scss";


const makeMessage = (action) => {
    if(action === 'Rating') return 'Avaliou';
    else if(action === 'LessonStatus') return 'Marcou como concluído';
}


const selectDate = (activity, action) => {
    if(action === 'Rating') return activity.trackable.updated_at;
    else return activity.trackable.completed_at;
}

const makeDate = (date, months, action) => {
    let month = date.slice(5, -13);
    let year = date.slice(0, -16);
    let day = date.slice(8, -10)
    let time = date.slice(11, -4);

    return `${day} de ${months[Number(month) - 1]} , ${year}, ${time}`;

}

const makeXP = (action) => {
    if(action === 'Rating') return '25';
    else return '50';
}

const makeIcon = (action) => {
    if(action === 'Rating') return faStar;
    else return faCheckCircle;
}

function Activity({activity}){
    const [months, setMonths] = useState(['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']);


    return(
        <Fragment>
            <Box className="activity-box">
                <Column.Group breakpoint="mobile">
                    <Column size={1}>
                        <Icon>
                            <FontAwesomeIcon className="activity-icon" icon={makeIcon(activity.trackable_type)}/>
                        </Icon>
                    </Column>
                    <Column>
                        <Content>
                            <p>{`${makeMessage(activity.trackable_type)} ${activity.lesson.title} `}</p>
                        </Content>
                        <Level>
                            <Level.Item align="left">
                                <Level.Item >
                                    <p className="date-field" >{`${activity.course.name} - ${makeDate(selectDate(activity, activity.trackable_type), months)}`}</p>
                                </Level.Item>
                            </Level.Item>
                        </Level>
                    </Column>
                    <Column size={1}>
                        <p>{`${makeXP(activity.trackable_type)}`}XP</p>
                    </Column>

                </Column.Group>
            </Box>
        </Fragment>
    );
}

export default Activity;