import React, { useState, useEffect } from "react";
import { Fragment } from "react/cjs/react.production.min";
import ContactInfo from "../form-components/contact-info";
import AdressInfo from "../form-components/adress-info";
import DatesInfo from "../form-components/dates-info";
import CourseInfo from "../form-components/course-info";
import PaymentInfo from "../form-components/payment-info";
import "../../../styles/right-screen.scss";
import { faPaperPlane, faEdit } from "@fortawesome/free-solid-svg-icons";
import FormButton from "../../form-button";
import StudentsServices from "../../../services/students";
import FinalQuestions from "../form-components/final-questions";
import FirstModal from "../../modals/first-modal";
import { Column, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotesServices from "../../../services/notes";
import NotificationModal from "../../modals/notification-modal";
import ActivitiesModal from "../../modals/activities-modal";



function StudentRegistrationForm({ data, setData, setIsFieldsInvisible,
    isFieldsInvisible, noteList, setNoteList, 
     optionsHistoric, setOptionsHistoric }) {
    const [fields, setFields] = useState({ ...data });
    const [isButtonUnvisible, setIsButtonUnvisible] = useState(false);
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);
    const [notificationText, setNotificationText] = useState('');


    useEffect(() => {
        adjustDataBack(data);
        setFields(() => { return { ...data } })
        setNoteList(() => { return data.observacoes })
    }, [data]);



    const clearInfo = (info) => {
        return info.replace(/[^0-9]/g, '');

    }

    useEffect(() => {
        let role = localStorage.getItem('role');
        if (role !== '1' && role !== '2') {
            setIsFieldsInvisible(true);
            setIsButtonUnvisible(true);
        }
        return () => {
            const historic = optionsHistoric;
            historic.push("studentRegistration");
            setOptionsHistoric(historic);
        }
    }, [])

    const adjustData = (student) => {
        student.endereco.numero === ''
            ? student.endereco.numero = 0
            : student.endereco.numero = Number(student.endereco.numero);


        student.valor_pago !== ''
            ? student.valor_pago = Number(student.valor_pago.replace(',', '.'))
            : student.valor_pago = 0;

        student.valor_da_renovacao === ''
            ? student.valor_da_renovacao = 0
            : student.valor_da_renovacao = Number(student.valor_da_renovacao);

        student.valor_dois_anos === ''
            ? student.valor_dois_anos = 0
            : student.valor_dois_anos = Number(student.valor_dois_anos);

        student.aprovado = (student.aprovado.toLowerCase() === 'true');
        student.dois_anos = (student.dois_anos.toLowerCase() === 'true');

        student.renovou = (student.renovou.toLowerCase() === 'true');
        student.comunidade = (student.comunidade.toLowerCase() === 'true');
        student.telefone = clearInfo(student.telefone);
        student.celular = clearInfo(student.celular);
        student.curso_grammar = (student.curso_grammar.toLowerCase() === 'true');
        student.preparatorio_prova = (student.preparatorio_prova.toLowerCase() === 'true');
    }

    const adjustDataBack = async (student) => {
        student.endereco.numero === 0 || student.endereco.numero === undefined
            ? student.endereco.numero = ''
            : student.endereco.numero = String(student.endereco.numero);
        student.valor_pago === 0 || student.valor_pago === undefined
            ? student.valor_pago = ''
            : student.valor_pago = String(student.valor_pago).replace('.', ',');
        student.valor_da_renovacao === 0 || student.valor_da_renovacao === undefined
            ? student.valor_da_renovacao = ''
            : student.valor_da_renovacao = String(student.valor_da_renovacao);
        student.valor_dois_anos === 0 || student.valor_dois_anos === undefined
            ? student.valor_dois_anos = ''
            : student.valor_dois_anos = String(student.valor_dois_anos);

        student.dois_anos = String(student.dois_anos);
        student.aprovado = String(student.aprovado);
        student.renovou = String(student.renovou);
        student.comunidade = String(student.comunidade);
        student.curso_grammar = String(student.curso_grammar);
        student.preparatorio_prova = String(student.preparatorio_prova);
    }

    const validateData = (student) => {
        console.log(student);
        Object.keys(student).forEach((item) => {
            if (student[item] === undefined) {
                return false;
            }
        })


        return true;
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        let aluno = { ...fields };
        adjustData(aluno);
        console.log(aluno)
        if (validateData(aluno)) {
            try {
                aluno.id !== ''
                    ? StudentsServices.update(aluno).then(() => {
                        setIsFieldsInvisible(true);
                        noteList.map((item, index) => {
                            if (item.id_estudante === '') {
                                item.id_estudante = fields.id;
                                NotesServices.post(item).then(() => {
                                    StudentsServices.getPerId(fields.id).then((response) => { setData(response.data); console.log(response.data) })
                                })

                            }
                        })
                    })
                    : StudentsServices.register(aluno).then((response) => {
                        setIsFieldsInvisible(true)
                        setFields({
                            ...fields,
                            id: response.data.id
                        })
                        noteList.map((item, index) => {
                            item.id_estudante = response.data.id;
                            NotesServices.post(item).then(() => {
                                StudentsServices.getPerId(item.id_estudante).then((response) => { setData(response.data); console.log(response.data) })
                            })
                        })
                    })
            }
            catch (error) {
                setNotificationText(error);
                setIsNotificationVisible(true);
            }

        }
    }

    return (
        <Fragment>
            <form className="right-content" onSubmit={handleSubmit}>
                <ContactInfo
                    fields={fields}
                    setFields={setFields}
                    isFieldsInvisible={isFieldsInvisible}
                    data={data} />
                <AdressInfo
                    fields={fields}
                    setFields={setFields}
                    isFieldsInvisible={isFieldsInvisible}
                    data={data} />
                <DatesInfo
                    fields={fields}
                    setFields={setFields}
                    isFieldsInvisible={isFieldsInvisible}
                    data={data} />
                <PaymentInfo
                    fields={fields}
                    setFields={setFields}
                    isFieldsInvisible={isFieldsInvisible}
                    data={data} />
                <CourseInfo
                    fields={fields}
                    setFields={setFields}
                    isFieldsInvisible={isFieldsInvisible}
                    data={data} />
                <FinalQuestions
                    fields={fields}
                    setFields={setFields}
                    isFieldsInvisible={isFieldsInvisible} />
                <Column.Group>
                    <Column size="one-third">
                        {isFieldsInvisible ?
                            <Button
                                disabled={isButtonUnvisible}
                                type="button"
                                className="right-screen-button"
                                onClick={() => setIsFieldsInvisible(false)}

                            >
                                <Icon size="small" align="left">
                                    <FontAwesomeIcon icon={faEdit} />
                                </Icon>
                                <span>Editar Informações</span>
                            </Button>
                            :
                            <FormButton
                                icon={faPaperPlane}
                                text="Salvar dados"
                                isButtonUnvisible={isButtonUnvisible} />}
                    </Column>

                    <Column>
                        <FirstModal
                            noteList={noteList}
                            setNoteList={setNoteList}
                            fields={fields}
                            setIsFieldsInvisible={setIsFieldsInvisible}
                            setFields={setFields}
                            adjustDataBack={adjustDataBack} />

                    </Column>
                    <Column>
                        <ActivitiesModal id={fields.id} />
                    </Column>

                </Column.Group>


            </form>
            <NotificationModal
                text={notificationText}
                isModalVisible={isNotificationVisible}
                setIsModalVisible={setIsNotificationVisible}
            />
        </Fragment>
    );
}

export default StudentRegistrationForm;