import React, { useState, useEffect } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Modal, Delete, Button, Field, Label, Control, Input, Help, Icon } from "rbx";
import UsersService from "../../../../services/users";
import ChangePasswordSecondStep from "../second-step";
import NotificationModal from "../../../modals/notification-modal";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../../../styles/change-password.scss';

function ChangePasswordFirstStep({ isModalVisible = false, setIsModalVisible = false,
    root, indexToEdit }) {
    const [fields, setFields] = useState({
        email: '',
        senha: ''
    })
    const [isSecondStepVisible, setIsSecondStepVisible] = useState(false);
    const [token, setToken] = useState();
    const [error, setError] = useState();
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);
    const [notificationText, setNotificationText] = useState();
    const [fieldType, setFieldType] = useState('password');
    const [fieldIcon, setFieldIcon] = useState(faEye);

    useEffect(() =>{
        return () =>{console.log('Componente sendo desmontado')}
    }, [])


    const HandleClick = async () => {
        if (root === 'header') {
            let response = await UsersService.getToken(fields);

            if (response !== 'unauthorized') {
                if (response.data.code) {
                    setToken(response.data.code);
                    setIsSecondStepVisible(true);
                    setFields({ email: '', senha: '' });
                }
            } else {
                setError('danger');
            }
        } else {
            let response = await UsersService.getTokenPerId(indexToEdit, fields);

            if (response !== 'unauthorized') {
                if (response.data.code) {
                    setIsNotificationVisible(true);
                    setNotificationText(response.data.code);
                    setFields({ email: '', senha: '' });
                }
            } else {
                setError('danger');
            }
        }

    }


    const handleChange = (e) => {
        setFields({
            ...fields,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    const handleShowPasswordClick = (e) => {
        e.preventDefault();
        if (fieldType === 'password') {
            setFieldType('text');
            setFieldIcon(faEyeSlash);
        }
        else {
            setFieldType('password');
            setFieldIcon(faEye);
        }
    }

    function handleOutsideClick(e){
        if(e.target.id === "modal") setIsModalVisible(false);
    }

    return (
        <Fragment>
            <Modal active={isModalVisible}>
                <Modal.Background id="modal" onClick={handleOutsideClick}/>
                <Modal.Card>
                    <Modal.Card.Head>
                        <Modal.Card.Title>Autenticação</Modal.Card.Title>
                        <Delete onClick={() => {
                            setIsModalVisible(false);
                            setFields({ email: '', senha: '' });
                        }} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <Field>
                            <Label>Email</Label>
                            <Control>
                                <Input
                                    type="email"
                                    name="email"
                                    value={fields.email}
                                    onChange={handleChange}
                                    color={error}
                                >
                                </Input>
                            </Control>

                        </Field>
                        <Field>
                            <Label>Senha</Label>
                            <Field kind='addons'>
                                <Control className='senha-input'>
                                    <Input
                                        color={error}
                                        type={fieldType}
                                        name="senha"
                                        value={fields.senha}
                                        onChange={handleChange}
                                    >
                                    </Input>
                                </Control>
                            <Control>
                                <Button
                                    as='a' 
                                    onClick={handleShowPasswordClick}>
                                    <Icon size='small'>
                                        <FontAwesomeIcon icon={fieldIcon} />
                                    </Icon>
                                </Button>
                            </Control>
                            </Field>
                            
                            {error
                                ? <Help color={error} >Dados inválidos</Help>
                                : ''}

                        </Field>
                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        <Button 
                            onClick={HandleClick} 
                            className="first-step-confirm"
                            >Confirmar</Button>
                        <Button 
                            className="first-step-cancel"
                            onClick={() => {
                            setFields({ email: '', senha: '' });
                            setIsModalVisible(false)
                        }} >Cancelar</Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </Modal>
            <ChangePasswordSecondStep
                showCode={false}
                isModalVisible={isSecondStepVisible}
                setIsModalVisible={setIsSecondStepVisible}
                changeToken={token}
                isNotificationVisible={isNotificationVisible}
                setIsNotificationVisible={setIsNotificationVisible}
                setIsFirstStepVisible={setIsModalVisible}
                setNotificationText={setNotificationText}
            />
            <NotificationModal
                isModalVisible={isNotificationVisible}
                setIsModalVisible={setIsNotificationVisible}
                text={notificationText}
            />
        </Fragment>
    );
}

export default ChangePasswordFirstStep;