import React, { useState, useEffect } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Modal, Delete, Button} from "rbx";
import '../../../styles/notification-modal.scss'

function NotificationModal({text, isModalVisible, setIsModalVisible}){

    function handleOutsideClick(e){
        if(e.target.id === "modal") setIsModalVisible(false);
    }

    return(
        <Fragment>
            <Modal active={isModalVisible}>
                <Modal.Background id="modal" onClick={handleOutsideClick} />
                <Modal.Card>
                    <Modal.Card.Head>
                        
                        <Delete onClick={() => {
                            setIsModalVisible(false);
                        }} />
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <p className="notification-text">{text}</p>
                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        <Button 
                            onClick={() => setIsModalVisible(false)} 
                            className="notification-button"
                        >Confirmar</Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </Modal>
        </Fragment>
    );
}

export default NotificationModal;