import React, { useState, useEffect } from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Field, Control, Button, Icon, Title } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/menu-button.scss"

function MenuButton({name, handleClick, icon, text, isButtonDisabled = false}) {
   
    
    return (

        <Fragment>
            <Field>
                <Field.Label />
                <Field.Body>
                    <Field>
                        <Control>
                            <Button 
                                className="menu-button" 
                                name={name} 
                                onClick={handleClick}
                                disabled={isButtonDisabled}
                                >
                                <Icon className="menu-button-icon">
                                    <FontAwesomeIcon icon={icon} />
                                </Icon>
                                <Title as="p" subtitle size={7} className="menu-button-text">
                                    {text}
                                </Title>

                            </Button>

                        </Control>
                    </Field>
                </Field.Body>
            </Field>
        </Fragment>
    );
}

export default MenuButton;