import Api from "./api";

const ClassesServices = {
    getClasses : async() =>{
        const response = await Api.get('/classes');

        return response.data;
    }
}

export default ClassesServices;